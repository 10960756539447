a {
	color: #333333;
}

.show-theme-options {
	&:hover, &:focus {
		background: #333333;
	}
}

.btn-primary:hover, .btn-black:hover, .btn-primary:focus, .btn-black:focus {
	background: #333333;
}

.top-bar {
	background: rgba(0,0,0,0.4);
	.cnt-account ul > li {
		border-left: 1px solid #666;
		&:first-child { border-left: none; }
		&:last-child { border-right: none; }
		a {
			color: #ddd;
			&:hover, &:focus {
				color: #ffffff;
			}
		}
	}
	.cnt-block > ul.list-inline > li {
		> a {
		    background: rgba(255,255,255,0.95);
		    color: #ccc;
		    border: 1px solid #999;
		    border-radius: 2px;
		    span {
		    	color: #363a40;
		    	&.value { color: #87898c; }
		    }
			.caret {
				color: #363a40;
			}
		}
		.dropdown-menu li a {
			color: #999;
			&:hover, &:focus {
				color: #222 !important;
			}
		}
	}
}

.main-header .top-search-holder .contact-row .icon {
	color: #333333;
}
.product .cart .action ul li.add-cart-button .btn-primary {
	background: #589013;
	border-radius: 0;
	&:hover, &:focus {
		background: #477310;
	}
}
.product .cart .action ul li.add-cart-button .btn.btn-primary.icon {
	border-radius: 0;
	background: #3f690b;
}

.top-cart-row .dropdown-cart {
	.lnk-cart .items-cart-inner .total-price-basket .total-price {
		color: #333333;
	}
	.dropdown-menu {
		.cart-item.product-summary {
			.name a {
				&:hover, &:focus {
					color: #333333;
				}
			}
			.price {
				color: #333333;
			}
			.action a {
				&:hover, &:focus {
					color: #333333;
				}
			}
		}
		.cart-total .price {
			color: #333333;
		}
	}
}

.main-header {
	background: rgba(0,0,0,0.3);
	.top-search-holder .search-area {
		border: none;
		margin-top: 25px;
		input.search-field {
			width: calc(100% - 50px);
		    border-radius: 0;
		    background: rgba(255,255,255,0.95);
		    color: #696969;
		    font-family: 'Montserrat', sans-serif;
		    font-size: 16px;
		    font-weight: 400;
		    padding: 11px 15px 10px;
		}
		a.search-button {
			width: 50px;
		}
	}
	.top-search-holder .search-area .categories-filter .dropdown-menu li a {
		&:hover, &:focus {
			color: #333333;
		}
	}
	.top-cart-row {
	    padding-top: 26px;
	    .dropdown-cart {
	    	.lnk-cart {
				border: none;
				background: rgba(255,255,255,0.95);
	    	}
	    }
	}
}

.header-style-1 {
	background: url('../images/header.jpg');
	.header-nav {
		background: rgba(0,0,0,0.85);
	}
}

.cnt-home .header-style-1 {
	.header-nav .navbar .navbar-nav > li {
		&.active {
			background: #589013;
		}
		> a {
			&:hover, &:focus {
				background: #589013;
			}
		}
	}
	&.header-style-2 .header-nav .navbar .navbar-nav > li.active {
		background: #333333;
	}
}

.cnt-homepage .header-style-1.header-style-2 .header-nav .navbar .navbar-nav > li.active, .cnt-home .header-style-1.header-style-3 .header-nav .navbar .navbar-nav > li.active {
	background: #333333;
}

.header-style-2 .header-nav .navbar-default .navbar-collapse .navbar-nav > li {
	> a {
		&:hover, &:focus {
			background: #333333 !important;
		}
	}
	&.open {
		background: #333333;
	}
}

.sidebar .side-menu nav .nav > li a {
	&:hover, &:focus, &:hover:after, &:focus:after {
		color: #333333;
	}
}

.info-box .info-box-heading.green {
	color: #333333;
}

.scroll-tabs .nav-tab-line li a {
	&:hover, &:focus {
		color: #333333;
	}
}

.product {
	.product-image {
		.image {
			a {
				img {
					// border: 1px solid #f1f1f1;
				}
			}
		}
	}
	.product-info {
		.name a {
			&:hover, &:focus {
				color: #333333;
			}
		}
		.product-price .price {
			color: #333333;
		}
	}
	.cart .action ul li {
		&.lnk a {
			&:hover, &:focus {
				color: #333333;
			}
		}
		&.add-cart-button .btn-primary {
			background: #589013;
			&:hover, &:focus {
				background: #477310;
			}
			&.icon {
				&:hover, &:focus {
					//background: #589013;
				}
			}
		}
	}
}

.tag.sale {
	background: #333333;
}

.copyright-bar .copyright a {
	color: #333333;
}

.blog-slider-container .blog-slider .blog-post-info .name a {
	&:hover, &:focus {
		color: #333333;
	}
}

.footer .links-social .social-icons a {
	&:hover, &:focus, &.active {
		background: #333333;
	}
}

.latest-tweet .re-twitter .comment {
	a, .icon .fa-stack-2x {
		color: #333333;
	}
}

.footer {
	.contact-information .media {
		.icon .fa-stack-2x {
			color: #333333;
		}
		.media-body a {
			&:hover, &:focus {
				color: #333333;
			}
		}
	}
	.footer-bottom .module-body ul li a {
		&:hover, &:focus {
			color: #333333;
		}
	}
}

.product-tag .item {
	&.active, &:hover, &:focus {
		background: #333333;
	}
}

.hot-deals .product-info {
	.product-price .price {
		color: #333333;
	}
	.name a {
		&:hover, &:focus {
			color: #333333;
		}
	}
}

.custom-carousel .owl-controls {
	.owl-prev:hover, .owl-next:hover, .owl-prev:focus, .owl-next:focus {
		background: #589013;
	}
}

.yamm .dropdown-menu {
	.title {
		&:hover, &:focus {
			color: #333333;
		}
	}
	li a {
		&:hover, &:focus {
			color: #333333;
		}
	}
}

.breadcrumb ul {
	li.active {
		color: #589013;
	}
	a {
		&:hover, &:focus {
			color: #589013;
		}
	}
}

.filters-container {
	.nav-tabs.nav-tab-box li.active a .icon {
		color: #333333;
	}
	.pagination-container ul li {
		&.active a {
			color: #333333;
		}
		&.prev:hover, &.next:hover, &.prev:focus, &.next:focus {
			background: #333333;
		}
		a {
			&:hover, &:focus {
				color: #333333;
			}
		}
	}
	.lbl-cnt .dropdown.dropdown-med .dropdown-menu li a {
		&:hover, &:focus {
			color: #333333;
		}
	}
}

.sidebar {
	.sidebar-module-container .sidebar-widget .sidebar-widget-body {
		.accordion .accordion-group .accordion-heading .accordion-toggle {
			color: #333333;
			&:hover, &:focus, &:after {
				color: #333333;
			}
		}
		.price-range-holder .slider .slider-track .slider-handle {
			border: 5px solid #333333;
		}
		.list li a {
			&:hover, &:focus {
				color: #333333;
			}
		}
		.compare-report span {
			color: #333333;
		}
	}
	.sidebar-widget .advertisement .owl-controls .owl-pagination .owl-page {
		&.active span, &:hover span {
			background: #333333;
		}
	}
}

.single-product {
	.gallery-holder .gallery-thumbs .owl-item .item:hover {
		border: 1px solid #333333;
	}
	.product-info {
		.rating-reviews .reviews .lnk {
			&:hover, &:focus {
				color: #333333;
			}
		}
		.price-container .price-box .price {
			color: #333333;
		}
		.quantity-container .cart-quantity .arrows .arrow {
			&:hover, &:focus {
				color: #333333;
			}
		}
		.product-social-link .social-icons ul li a {
			&:hover, &:focus {
				background: #333333;
			}
		}
	}
	.product-tabs .nav.nav-tabs.nav-tab-cell li {
		a {
			&:hover, &:focus {
				background: #333333;
			}
			&:hover:after, &:focus:after {
				border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #333333;
			}
		}
		&.active a {
			background: #333333;
			&:after {
				border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #333333;
			}
		}
	}
}

.cart .action .add-cart-button .btn.btn-primary.icon {
	&:hover, &:focus {
		background: #333333;
	}
}

.product-tabs .tab-content .tab-pane .product-reviews .reviews .review {
	.review-title .date span, .author span {
		color: #333333;
	}
}

#owl-main {
	&:hover {
		.owl-prev:hover, .owl-next:hover {
			background: #589013;
		}
	}
	.owl-controls .owl-pagination .owl-page {
		&:active span, &:hover span {
			background: #333333;
		}
	}
}

.cart .action {
	ul .lnk .add-to-cart {
		&:hover, &:focus {
			color: #333333 !important;
		}
	}
	.add-to-cart {
		&:hover, &:focus {
			color: #333333 !important;
		}
	}
}

.homepage-container {
	.product {
		.tag.hot {
			background: #333333;
		}
		.product-info .name a {
			&:hover, &:focus {
				color: #333333;
			}
		}
	}
	.btn-primary {
		&:hover, &:focus {
			background: #333333;
			border: 2px solid #333333;
		}
	}
}

.category-product .cart .action ul li .add-to-cart {
	&:hover, &:focus {
		background: #333333;
	}
}

.furniture-container .product .btn-primary {
	&:hover, &:focus {
		background: #333333;
	}
}

.header-style-3 .header-nav .navbar-default .navbar-collapse .navbar-nav > li {
	> a {
		&:hover, &:focus {
			background: #333333;
		}
	}
	&.open {
		background: #333333;
	}
}

#owl-single-product-thumbnails .owl-controls .owl-pagination .owl-page {
	&.active span {
		background: #333333 !important;
	}
	span {
		&:hover, &:focus {
			background: #333333 !important;
		}
	}
}

.cnt-homepage .sidebar .sidebar-module-container .sidebar-widget .sidebar-widget-body .price-range-holder .slider .slider-track .slider-handle.max-slider-handle {
	background: #333333;
}

.blog-page {
	.blog-post h2 a {
		&:hover, &:focus {
			color: #333333;
		}
	}
	.sidebar .sidebar-module-container .sidebar-widget .nav-tabs > li.active > a {
		color: #589013;
		&:hover, &:focus {
			color: #589013;
		}
	}
	.blog-post .social-media a {
		&:hover, &:focus {
			background-color: #333333;
		}
	}
	.sidebar .sidebar-module-container {
		.search-area .search-button:after {
			color: #589013;
		}
		.sidebar-widget .blog-post h4 a {
			&:hover, &:focus {
				color: #333333;
			}
		}
	}
	.blog-post-author-details .author-social-network button .twitter-icon {
		background-color: #333333;
	}
	.blog-review .review-action a {
		color: #333333;
		&:hover, &:focus {
			color: #333333;
		}
	}
	.blog-post-author-details .author-social-network .dropdown-menu > li > a {
		&:hover, &:focus {
			color: #333333;
		}
	}
}

.checkout-box {
	.checkout-steps {
		.panel .panel-heading .unicase-checkout-title > a:not(.collapsed) span {
			background-color: #333333;
		}
		.checkout-step-01 .already-registered-login a {
			&:hover, &:focus {
				color: #333333;
			}
		}
	}
	.checkout-progress-sidebar .panel-body ul li a {
		&:hover, &:focus {
			color: #333333;
		}
	}
}

.contact-page .contact-info .contact-i {
	background-color: #333333;
}

.shopping-cart {
	.shopping-cart-table table tbody tr {
		.cart-product-name-info {
			h4 a {
				&:hover, &:focus {
					color: #333333;
				}
			}
			.cart-product-info span span {
				color: #589013;
			}
		}
		.cart-product-edit a {
			&:hover, &:focus {
				color: #333333;
			}
		}
	}
	.cart-shopping-total table thead tr th .cart-grand-total {
		color: #589013;
	}
}

.shopping-cart-table table tbody tr td .quant-input .arrows .arrow {
	&:hover, &:focus {
		color: #333333;
	}
}

.logo-color {
	fill: #333333;
}

.cnt-homepage .single-product {
	.product-info-block {
		.form-control .dropdown-menu ul li a {
			&:hover, &:focus {
				color: #333333;
			}
		}
		.txt.txt-qty {
			color: #333333;
		}
	}
	.product-tabs .nav-tab-cell-detail {
		border-bottom: none;
		li {
			margin-right: 10px;
			padding: 0;
			a {
				&:hover, &:focus {
					background: #333333;
					border: 2px solid #333333;
				}
			}
			&.active a {
				background: #333333;
				border: 2px solid #333333;
			}
		}
	}
}

.shopping-cart .estimate-ship-tax table tbody .unicase-form-control .dropdown-menu.open ul li a {
	&:hover, &:focus {
		color: #333333;
	}
}

.yamm .dropdown-menu {
	border-top-color: #333333;
}

.product-comparison .compare-table tr td {
	.product-price .price, .in-stock {
		color: #333333;
	}
}

.body-content .my-wishlist-page .my-wishlist table tbody .product-name a {
	&:hover, &:focus {
		color: #333333;
	}
}

.product-comparison .compare-table tr td {
	.product-price .price, .in-stock {
		color: #333333;
	}
}

.body-content .x-page .x-text {
	h1, a {
		color: #333333;
	}
}

.sign-in-page .register-form .forgot-password {
	color: #333333;
	&:hover, &:focus {
		color: #333333;
	}
}

.body-content .my-wishlist-page .my-wishlist table tbody .price, .terms-conditions-page .contact-form {
	color: #333333;
}