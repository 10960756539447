@import '//fonts.googleapis.com/css?family=Roboto:300,400,500,700';
// @import '//fonts.googleapis.com/css?family=Basic:300,400,500,700';

// Custom added styles
.header-style-1 .header-nav .navbar-default .navbar-collapse .navbar-nav > li > a {
	padding: 17px 23px 15px;
}
.top-bar {
	padding: 5px 0;
}
.header-nav .navbar-default .navbar-collapse .navbar-nav > li {
	> a {
		font-size: 15px;
	}
	&:hover , &:focus {
		background: rgba(0,0,0,0.95);
	}
}

.top-cart-row .dropdown-cart .dropdown-menu { width: 320px; }
.pagination {
	margin: 0;
	padding-left: 0;
	list-style: none;
	li {
		display: inline-block;
		padding-right: 5px;
		padding-left: 5px;

		a , span {
			position: relative;
			float: none;
			margin-left: 0;
			border: none;

			color: #666;
			display: inline-block;
			font-family: 'Roboto',sans-serif;
			font-size: 13px;
			line-height: 20px;
			padding: 0 3px;
			transition: all 0.2;

			&[rel=prev] , &[rel=next] {
				background: none repeat scroll 0 0 #ddd;
				border-radius: 3px;
				color: white;
				width: 20px;
				text-align: center;	
			}
			&:hover {
				color: #589013;
				background-color: #fff;
			}
		}
		&.disabled { display: none; }
		&.active {
			span {
				color: #589013;
				text-decoration: underline;
				background-color: #fff;
			}
		}
	}
}

.contact-title {
	h1 , h2 {
		font-size: 22px;
		color: #589013;
		font-family: 'FjallaOneRegular';
		text-transform: uppercase;
		display: inline-block;
		margin: 10px 0 15px 0;
		min-height: 30px;
		line-height: 30px;
	}
}

.title-with-bg {

	min-height: 250px;
    position: relative;
    margin-bottom: 30px;
    background-size: cover !important;
	
	h1, h2, h3 {
		position: absolute;
	    left: 0;
	    bottom: 0;
	    margin: 0;
	    padding: 15px 15% 18px 30px;
	    color: #fff;
	    text-transform: uppercase;
	    font-family: lato, sans-serif;
	    font-weight: 300;
	    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
	    
	    background: -moz-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5) 57%, rgba(0,0,0,0.4) 71%, rgba(0,0,0,0.25) 80%, rgba(0,0,0,0) 100%);
		background: -webkit-linear-gradient(left, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.5) 57%,rgba(0,0,0,0.4) 71%,rgba(0,0,0,0.25) 80%,rgba(0,0,0,0) 100%);
		background: linear-gradient(to right, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.5) 57%,rgba(0,0,0,0.4) 71%,rgba(0,0,0,0.25) 80%,rgba(0,0,0,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99000000', endColorstr='#00000000',GradientType=1 );

		/*
		background: -moz-linear-gradient(left, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.4) 56%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0.2) 80%, rgba(0,0,0,0) 100%);
		background: -webkit-linear-gradient(left, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.4) 56%,rgba(0,0,0,0.3) 70%,rgba(0,0,0,0.2) 80%,rgba(0,0,0,0) 100%);
		background: linear-gradient(to right, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.4) 56%,rgba(0,0,0,0.3) 70%,rgba(0,0,0,0.2) 80%,rgba(0,0,0,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00000000',GradientType=1 );
		///*/
	}
}

.listado-pedidos {
	width: 100%;
	th {
		font-weight: 300;
		font-size: 22px;
		width: 50%;
		line-height: 50px;
		background-color: #f6f6f6;
		padding: 0 15px;
		border-bottom: 2px solid #eee;
	}
	td {/* 
		padding: 15px;
		font-weight: 300;
		font-size: 14px;
		line-height: 26px;
		vertical-align: top;
		border-bottom: 1px solid #f3f3f3;
		background-color: #fdfdfd;
		b {
			font-weight: 500;
		} */
		ul.buttons {
			margin-top: 5px;
			li {
				a {
					height: 28px;
					line-height: 28px;
					margin-bottom: 3px;
					padding: 0 15px 0 0;

					i {
						height: 28px;
						width: 24px;
					}					
					&:hover {
						
					}
				}
			}
		}		
	}
	.order-row {
		padding: 15px 0;
		margin: 10px 0;
		line-height: 1.6em;
		border-radius: 10px;
		&:nth-of-type(even) { background: #f9f9f9; }
		&:nth-of-type(odd) { background: #eee; }

		.info , .course-row , .button-group {
			background: #fff;
			border-radius: 5px;
			box-shadow: 0 0 1px #0000000d;
			padding: 5px 10px;
		}
		.button-group {
			padding: 5px;
			> .btn {
				margin-right: 5px;
			}
		}

		.course-row {
			tr:not(:last-child) {
				border-bottom: 1px solid #f1f1f1;
			}
			tr {
				td {
					padding: 10px 5px;
				}
			}
			img {
				border-radius: 50%;
				margin-right: 10px;
			    box-shadow: 0px 1px 10px 1px rgba(0,0,0,0.1);
			}
			a {
				font-size: 12px;
				text-transform: uppercase;
			    font-weight: 600;
			    line-height: 1em;
			    &:hover {
			    	color: #7cb931;
			    }
			}
			.price {
				float: right;
			    font-weight: 500;
			    font-size: 20px;
			    white-space: nowrap;
			    width: 65px;
			    text-align: right;
			    color: #fb832b;
			}
		}
		.button-group {
			margin-top: 15px;
			width: 100%;
			.btn:hover , .btn:focus {
				background: #2b2a2a;
			}
			.btn-courses { background: #4caf50; &:hover { background: #339e38; } }
			.btn-invoice { background: #f1417d; &:hover { background: #e81d62; } }
			.btn-certificates { background: #fd6f43; &:hover { background: #ea4f1e; } }
			.btn-pay { background: #03a9f4; &:hover { background: #0784bd; } }

			.btn {
				position: relative;
				    padding-left: 30px;
				i {
					font-size: 18px;
				    position: absolute;
				    top: 8px;
				    left: 6px;
				}
			}
		}
	}
}


/* Rating */
.br-widget {
	height: 24px;
	a {
		background: url('../images/star.png');
		width: 24px;
		height: 24px;
		display: block;
		float: left;

		&:hover, &.br-active, &.br-selected {
			background-position: 0 24px;
		}
	}
}

/* course description */
.course_description {
	color: #777;
	font-size: 14px;
	strong {
		display: inline-block;
		padding: 10px 0;
	}
}

.cart-alert {
	position: absolute;
	background: #fff;
	color: white;
	width: 1px;
	height: 1px;	
	border-radius: 50%;
	transition: all 300ms;
	&.active {
		box-shadow: 0 0 4px rgba(0,0,0,0.5);
		border-radius: 3px;
		padding: 0 10px;
		color: #666;
		width: auto;
		height: auto;
		margin-top: -20px;
		line-height: 40px;
	}
}

/* Bootstrap callout */
.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  h4 {
	margin-top: 0;
	margin-bottom: 5px;
  }
  p:last-child {
	margin-bottom: 0;
  }
  code {
	border-radius: 3px;
  }
  + .callout {
	margin-top: -5px;
  }
}

.callout-default {
  border-left-color: #777;
  h4 {
	color: #777;
  }
}

.callout-primary {
  border-left-color: #428bca;
  h4 {
	color: #428bca;
  }
}

.callout-success {
  border-left-color: #5cb85c;
  h4 {
	color: #5cb85c;
  }
}

.callout-danger {
  border-left-color: #d9534f;
  h4 {
	color: #d9534f;
  }
}

.callout-warning {
  border-left-color: #f0ad4e;
  h4 {
	color: #f0ad4e;
  }
}

.callout-info {
  border-left-color: #5bc0de;
  h4 {
	color: #5bc0de;
  }
}

// Footer menu
.footer-menu {
	li {
		a {
			color: #ccc;
			line-height: 30px;
			padding: 0 5px;
			&:hover {
				color: #fff;
			}
		}
	}
}

// HOME
.intro {
	margin: 40px 0 0;
	h1 , h2 {
		font-family: FjallaOneRegular;
		font-size: 20px;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	p {
		text-align: justify;
	}
	img {
		width: 100%;
	}
}
 // FAQS
 .faqs {
	h2 {
		font-family: 'Open Sans';
		font-size: 13px;
		font-weight: 600;

	} 	
	p {
		margin-bottom: 2.5rem;
		font-family: 'Open Sans';
		font-weight: 300;
		line-height: 2.5rem;
	}
 }
 /* Send to friend */
 #send_to_friend {
	.modal-backdrop {
		background-color: rgba(0,0,0,0.5);
	}
	.modal-dialog {
		margin-top: 15%;
		.modal-content {
			border-color: #589013;
			border-style: solid;
			border-width: 5px 0 0;
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

			.modal-header {
				padding: 8px 15px;
				border-bottom: 1px solid #f3f3f3;

				h4 {color: #589013; font-family: FjallaOneRegular; font-size: 20px; text-transform: uppercase; }
			}
		}
	}
 }

 /* emails input */
div.tagsinput {
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	color: #555;
	display: block;
	font-size: 14px;
	height: 34px;
	line-height: 1.42857;
	padding: 6px 12px;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	width: 100% !important;

	&:focus {
		border-color: #66afe9;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
		outline: 0 none;
	}


	span.tag {
		font-family: "Open Sans", sans-serif;
		font-weight: 300;
		text-transform: none;
		border-radius: 3px;
		display: block;
		float: left;		
		text-decoration: none;
		background: #d0ecae;
		color: #537327;
		margin-right: 5px;
		margin-bottom: 5px;
		font-size: 13px;
		a {
			font-weight: bold;
			color: #82ad2b;
			text-decoration: none;
			font-size: 11px;
		}
	}
	input {
		width: auto !important;
		margin: 0px;
		font-family: helvetica;
		font-size: 13px;
		border: 1px solid transparent;
		padding: 5px;
		background: transparent;
		color: #000;
		outline: 0px;
		margin-right: 5px;
		margin-bottom: 5px;
	}
	div {
		display: block;
		float: left;
	}
}
.tags_clear {
	clear: both;
	width: 100%;
	height: 0px;
}
.not_valid {
	background: #FBD8DB !important;
	color: #90111A !important;
}

h1.title {
	font-size: 22px;
	color: #589013;
	font-family: 'FjallaOneRegular';
	text-transform: uppercase;
	display: inline-block;
	margin: 10px 0 0 0;
	min-height: 30px;
	line-height: 30px;
}

/* Custom css */
@media screen and (min-width: 1300px)  and (max-width: 1366px) {
	.top-bar {
		padding: 3px 0;
	}
	.main-header {
		padding: 15px 0;
	}
	.header-style-1 .header-nav .navbar-default
	{
		min-height: 32px;
		.navbar-collapse .navbar-nav > li > a {
			line-height: 20px;
			padding: 10px 30px;
			font-size: 18px;
		}
	}
	/* Cesta */
	.shopping-cart {
		.shopping-cart-table .table > thead > tr > th {
			padding: 8px 15px;
		}
		.shopping-cart-table .table > tbody > tr > td {
			padding: 12px 30px;
		}
		.shopping-cart-table .table > tfoot > tr > td {
			padding: 8px 30px;
		}
	}
	.shopping-cart .shopping-cart-table {
		margin-bottom: 5px;
	}
	.shopping-cart .cart-shopping-total table thead tr th {
		padding: 10px 50px;
	}
	.shopping-cart .cart-shopping-total table tbody tr td {
		padding: 10px 50px;
	}
}

ul {
	li.cat-item {
		padding-left: 8px;
		a {
			color: #666666;
			clear: both;
			display: block;
			font-family: 'Roboto', sans-serif;
			font-size: 13px;
			font-weight: 300;
			line-height: 26px;
			&:before {
				content: "\f105";
				font-family: fontawesome;
				font-size: 14px;
				line-height: 15px;
				margin: 0 5px 0 0;
			}
			&:hover {
				color: #589013;
			}
		}
	}
}


.offer-overlay {
	display: none;
	position: fixed;
	background: rgba(0,0,0,0.8);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.offer-banner {
	display: none;
	position: fixed;
	text-align: center;

	.banner {
		position: relative;
		.close {
			position: absolute;
		    right: 5px;
		    top: 2px;
		    font-size: 32px;
		}
		.countdown {
		    position: absolute;
		    right: 119px;
		    width: 223px;
		    bottom: 70px;
		    text-align: left;
		    text-shadow: 0px 2px 1px #000;
		    font-size: 18px;
		}
	}
	.button {
		margin-top: 15px;
		display: inline-block;
		color: #fff;
		padding: 8px 25px;
		font-size: 19px;
		border-radius: 3px;
		border-bottom: 2px solid #610809;
		opacity: 1;
		background: #c0272d;
		background: -moz-linear-gradient(top,#c0272d 0,#bd262c 100%);
		background: -webkit-linear-gradient(top,#c0272d 0,#bd262c 100%);
		background: linear-gradient(to bottom,#c0272d 0,#bd262c 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c0272d', endColorstr='#bd262c', GradientType=0);
		box-shadow: 0 0 18px 1px rgba(0,0,0,0.5) inset;

		&:hover {
			color: #eee;
			opacity: 0.95;
		}

	}

}
.cart-error {
	background: #bf2424;
    width: 100%;
    display: block;
    color: #fff;
    padding: 15px 20px;
    font-size: 13px;
    margin-bottom: 20px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.6);
    border-left: 5px solid #7d1212;
}


.single-product {
	#owl-single-product-thumbnails {
		img {
			width: 100%;
		}
		.owl-controls {
			position: absolute;
			text-align: center;
			top: auto;
			width: 100%;
			margin-top: 10px;
			.owl-pagination .owl-page {
				display: inline-block;
				span {
					background: none repeat scroll 0 0 #ddd;
					border: medium none;
					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					border-radius: 50%;
					display: block;
					height: 12px;
					margin: 0 5px;
					-webkit-transition: all 200ms ease-out 0s;
					-moz-transition: all 200ms ease-out 0s;
					-o-transition: all 200ms ease-out 0s;
					transition: all 200ms ease-out 0s;
					width: 12px;
					cursor: pointer;
				}
			}
		}
	}
}
.product-tabs .tab-content .tab-pane .product-reviews .reviews {
	.review {
		border-bottom: 1px solid #f6f6f6;
		padding: 5px 0;
		.date {
			color: #aaa;
			color: #589013 !important;
			font-weight: 300;
			font-size: 12px;
			padding-left: 2px;
		}
		.name {
			font-weight: 500;	
			padding-left: 2px;
		}
		.text {
			font-weight: 300;	
			font-size: 13px;
		}
	}
}

.main-header {
	padding: 20px 0 25px;
	.logo-holder {
		margin-top: 20px;
		margin-bottom: -20px;
	}
}
.yamm {
	.dropdown-menu {
		box-shadow: 0 2px 9px -1px #00000073;
		border: none;
		.yamm-content {
			padding: 0;
			.links li {
				border-bottom: 1px solid #f1f1f1;
				&:last-child {
					border-bottom: none;
				}
				a {
					padding: 8px 0px;
					line-height: 1.5em;
				}
			}
		}
		li {
			a {
				color: #666;
				&:hover, &:focus {
 					margin-left: 0;
 				}
			}
		}
	}
}

.sidebar {
	.side-menu {
		.head {
			padding: 12px 15px;
			.icon {
				margin-right: 15px;
			}
		}
		nav .nav > li {
			> {
				a:after {
					display: none;
				}
			}
			&.active {
				background: #e9e9e9;
				> ul {
					> li {
						background: #fff;
						border-bottom: 1px solid #f6f6f6;
						transition: all .3s ease 0s;
						border-left: 1px solid transparent;
						&:hover {
							border-left: 3px solid #bddd96;
						}
						a {
							padding: 3px 5px 3px 20px;
							color: #7cae3f;
							display: block;
							&:hover {
							}
						}
					}
				}
			}
			> {
				a {
					padding: 7px 13px;
				}
			}
		}
	}
}

#owl-main {
	.item {
		.caption {
			right: 5%;
			position: absolute;
			top: 14%;
			z-index: 100;
			padding: 3%;
			background-color: rgba(255, 255, 255, 0.85);
			border-radius: 4px;
			max-width: 48%;
			.big-text {
				font-size: 25px;
				line-height: 26px;
				.highlight {
					color: #71a72f;
				}
			}
			.excerpt span, .small span {
				&.price {
					color: #444;
					text-shadow: 0px 1px 2px white;
					> span {
						display: inline;
						text-decoration: line-through;
						color: #aaa;
						font-weight: 400;
						line-height: 30px;
						font-size: 14px;
						margin-left: 10px;
					}
				}
			}
		}
	}
}

.info-boxes {
	.info-box {
		.info-box-heading {
			font-size: 25px;
		}
	}
}

.product {
	.product-info {
		.name {
			a {
				display: inline-block;
				margin-right: 18px;
			}
		}
	}
}

.product {
	.cart {
		opacity: 1;
	}
}

.tag {
	border-radius: 50px;
	color: #fff;
	font-size: 13px;
	font-weight: 700;
	height: 22px;
	line-height: 21px;
	padding: 0 5px;
	right: 21px;
	text-align: center;
	text-transform: uppercase;
	top: 5px;
	z-index: 100;
}

.breadcrumb {
	ul {
		li {
			&:after {
				margin-right: 0px;
			}
		}
	}
}

.filters-container {
	.nav-tabs.nav-tab-box {
		li {
			a {
				.icon {
					font-size: 14px;
				}
			}
		}
	}
}

.single-product {
	.gallery-holder {
		#owl-single-product .single-product-gallery-item {
			img {
				width: 100%;
			}
		}
	}
	.product-info {
		.product-social-link {
			.social-icons {
				ul {
					li {
						a {
							color: #888888;
							font-size: 16px;
							-webkit-transition: all 0.2s linear 0s;
							-moz-transition: all 0.2s linear 0s;
							-o-transition: all 0.2s linear 0s;
							transition: all 0.2s linear 0s;
							width: 28px;
							height: 28px;
							line-height: 28px;
							text-align: center;
							&:hover, &:focus {
								-webkit-border-radius: 50px;
								-moz-border-radius: 50px;
								border-radius: 50px;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}

	#owl-single-product-thumbnails {
		img {
			width: 100%;
		}
		.owl-controls {
			position: absolute;
			text-align: center;
			top: auto;
			width: 100%;
			margin-top: 10px;
			.owl-pagination .owl-page {
				display: inline-block;
				span {
					background: none repeat scroll 0 0 #ddd;
					border: medium none;
					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					border-radius: 50%;
					display: block;
					height: 12px;
					margin: 0 5px;
					-webkit-transition: all 200ms ease-out 0s;
					-moz-transition: all 200ms ease-out 0s;
					-o-transition: all 200ms ease-out 0s;
					transition: all 200ms ease-out 0s;
					width: 12px;
					cursor: pointer;
				}
			}
		}
	}
}

.blog-page {
	.blog-post {
		h2 {
			font-family: 'FjallaOneRegular';
			font-size: 25px;
			text-transform: uppercase;
			a {
				color: #555;
			}
		}
	}
	.sidebar .sidebar-module-container .sidebar-widget .blog-post {
		.author:before, .review:before, .date-time:before {
			font-family: FontAwesome;
		}
	}
}

.contact-page .contact-form form {
	input {
		background-color: #f6f6f6;
	}
}

.btn-order {
	width: 16px;
	height: 16px;
	display: inline-block;
	position: absolute;
	right: 0;
	&:before , &:after {
		color: #d1d1d1;
		height: 8px;
		width: 16px;
		text-align: center;
		position: absolute;
		left: 0;
		font-family: "Ionicons";
	}
	&:before {
		content: "\f10d";
		left: 0;
	}
	&:after {
		content: "\f104";
		bottom: 0;
	}
	&.asc { &:before { color: #3c8dbc; } }
	&.desc { &:after { color: #3c8dbc; } }
}
.product .product-image .tag {
    position: absolute;
    width: auto;
    font-size: 11px;
    letter-spacing: 0;
    padding: 0;
    line-height: 1.2em;
    height: auto;
    padding: 3px 9px 2px;
    border-radius: 7px;
}

@import 'responsive';