/*===================================================================================*/
/*  GENERAL
/*===================================================================================*/
.green-text {
	color: #abd07e !important;
	&:hover {
		background-color: #abd07e !important;
		color: #fff !important;
	}
}
.blue-text {
	color: #3498db !important;
	&:hover {
		background-color: #3498db !important;
		color: #fff !important;
	}
}
.red-text {
	color: #ff6c6c !important;
	&:hover {
		background-color: #ff6c6c !important;
		color: #fff !important;
	}
}
.orange-text {
	color: #f39c12 !important;
	&:hover {
		background-color: #f39c12 !important;
		color: #fff !important;
	}
}
.dark-green-text {
	color: #12cca7 !important;
	&:hover {
		background-color: #12cca7 !important;
		color: #fff !important;
	}
}
.inline {
	display: inline-block;
	vertical-align: top;
}
ul {
	list-style: none;
}
a {
	-webkit-transition: all 0.2s linear 0s;
	-moz-transition: all 0.2s linear 0s;
	-o-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
}
body {
	font-size: 13px;
	color: #666666;
	background-color: #fff;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
}
ul {
	margin: 0;
	padding: 0;
}
a {
	outline: none !important;
	&:hover, &:active, &:focus {
		text-decoration: none;
	}
}
img[src="assets/images/blank.gif"] {
	background: url("../images/ajax.gif") no-repeat scroll center center #ffffff;
}
@font-face {
	font-family: 'FjallaOneRegular';
	src: url('../fonts/fjalla/fjallaone-regular.eot');
	src: url('../fonts/fjalla/fjallaone-regular.eot') format("embedded-opentype"), url('../fonts/fjalla/fjallaone-regular.woff2') format("woff2"), url('../fonts/fjalla/fjallaone-regular.woff') format("woff"), url('../fonts/fjalla/fjallaone-regular.ttf') format("truetype"), url('../fonts/fjalla/fjallaone-regular.svg#FjallaOneRegular') format("svg");
}
@font-face {
	font-family: 'BebasNeueBold';
	src: url('../fonts/bebas/bebasneuebold.eot');
	src: url('../fonts/bebas/bebasneuebold.eot') format("embedded-opentype"), url('../fonts/bebas/bebasneuebold.woff2') format("woff2"), url('../fonts/bebas/bebasneuebold.woff') format("woff"), url('../fonts/bebas/bebasneuebold.ttf') format("truetype"), url('../fonts/bebas/bebasneuebold.svg#BebasNeueBold') format("svg");
}
@font-face {
	font-family: 'BebasNeueRegular';
	src: url('../fonts/bebas/bebasneueregular.eot');
	src: url('../fonts/bebas/bebasneueregular.eot') format("embedded-opentype"), url('../fonts/bebas/bebasneueregular.woff2') format("woff2"), url('../fonts/bebas/bebasneueregular.woff') format("woff"), url('../fonts/bebas/bebasneueregular.ttf') format("truetype"), url('../fonts/bebas/bebasneueregular.svg#BebasNeueRegular') format("svg");
}
@font-face {
	font-family: 'PacificoRegular';
	src: url('../fonts/pacifico/pacifico.eot');
	src: url('../fonts/pacifico/pacifico.eot') format("embedded-opentype"), url('../fonts/pacifico/pacifico.woff2') format("woff2"), url('../fonts/pacifico/pacifico.woff') format("woff"), url('../fonts/pacifico/pacifico.ttf') format("truetype"), url('../fonts/pacifico/pacifico.svg#PacificoRegular') format("svg");
}
@font-face {
	font-family: 'LatoBold';
	src: url('../fonts/lato/lato-bold.eot');
	src: url('../fonts/lato/lato-bold.eot') format("embedded-opentype"), url('../fonts/lato/lato-bold.woff2') format("woff2"), url('../fonts/lato/lato-bold.woff') format("woff"), url('../fonts/lato/lato-bold.ttf') format("truetype"), url('../fonts/lato/lato-bold.svg#LatoBold') format("svg");
}
.btn-upper {
	text-transform: uppercase;
}
.m-t-20 {
	margin-top: 20px;
}
.m-t-15 {
	margin-top: 15px;
}
.m-t-10 {
	margin-top: 10px;
}
.btn-uppercase {
	text-transform: uppercase;
}
.btn-default {
	background: #cbc9c9;
	color: #fff;
	font-weight: 700;
	/*line-height:30px;*/
	-webkit-transition: all 0.2s linear 0s;
	-moz-transition: all 0.2s linear 0s;
	-ms-transition: all 0.2s linear 0s;
	-o-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
}
.btn-primary {
	-webkit-transition: all linear 0.2s;
	-moz-transition: all linear 0.2s;
	-ms-transition: all linear 0.2s;
	-o-transition: all linear 0.2s;
	transition: all linear 0.2s;
	background: #a8a8a8;
	color: #fff;
	border: none;
	font-size: 13px;
	line-height: 22px;
}
.btn-black {
	-webkit-transition: all linear 0.2s;
	-moz-transition: all linear 0.2s;
	-ms-transition: all linear 0.2s;
	-o-transition: all linear 0.2s;
	transition: all linear 0.2s;
	background: #3a3a3a;
	color: #fff;
	border: none;
	font-size: 15px;
	line-height: 30px;
	font-weight: 500;
	padding: 3px 22px;
}
.btn-primary:hover {
	color: #fff;
}
.btn-black {
	&:hover, &:focus {
		color: #fff;
	}
}
.btn-primary:focus {
	color: #fff;
}
.center-block {
	float: none;
}
.inner {
	padding-top: 120px;
	padding-bottom: 120px;
}
.inner-md {
	padding-top: 100px;
	padding-bottom: 100px;
}
.inner-sm {
	padding-top: 80px;
	padding-bottom: 80px;
}
.inner-xs {
	padding-top: 40px;
	padding-bottom: 40px;
}
.inner-vs {
	padding-top: 30px;
	padding-bottom: 30px;
}
.inner-top {
	padding-top: 120px;
}
.inner-top-md {
	padding-top: 100px;
}
.inner-top-sm {
	padding-top: 80px;
}
.inner-top-xs {
	padding-top: 40px;
}
.inner-top-vs {
	padding-top: 30px;
}
.inner-bottom {
	padding-bottom: 120px;
}
.inner-bottom-md {
	padding-bottom: 100px;
}
.inner-bottom-sm {
	padding-bottom: 80px;
}
.inner-bottom-xs {
	padding-bottom: 40px;
}
.inner-bottom-vs {
	padding-bottom: 60px;
}
.inner-left {
	padding-left: 75px;
}
.inner-left-md {
	padding-left: 60px;
}
.inner-left-sm {
	padding-left: 45px;
}
.inner-left-xs {
	padding-left: 30px;
}
.inner-right {
	padding-right: 75px;
}
.inner-right-md {
	padding-right: 60px;
}
.inner-right-sm {
	padding-right: 45px;
}
.inner-right-xs {
	padding-right: 30px;
}
.inner-right-vs {
	padding-right: 10px;
}
.outer {
	margin-top: 120px;
	margin-bottom: 120px;
}
.outer-md {
	margin-top: 100px;
	margin-bottom: 100px;
}
.outer-sm {
	margin-top: 80px;
	margin-bottom: 80px;
}
.outer-xs {
	margin-top: 40px;
	margin-bottom: 40px;
}
.outer-top {
	margin-top: 120px;
}
.outer-top-md {
	margin-top: 100px;
}
.outer-top-sm {
	margin-top: 80px;
}
.outer-top-xs {
	margin-top: 30px;
}
.outer-top-vs {
	margin-top: 40px;
}
.outer-top-small {
	margin-top: 50px;
}
.outer-bottom {
	margin-bottom: 120px;
}
.outer-bottom-md {
	margin-bottom: 100px;
}
.outer-bottom-sm {
	margin-bottom: 80px;
}
.outer-bottom-vs {
	margin-bottom: 60px;
}
.outer-bottom-xs {
	margin-bottom: 40px;
}
.outer-bottom-small {
	margin-bottom: 50px;
}
.outer-top-bd {
	margin-top: 50px;
}
.inner-bottom-30 {
	padding-bottom: 30px;
}
.inner-bottom-20 {
	padding-bottom: 20px;
}
@media (max-width: 767px) {
	.inner {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	.inner-md {
		padding-top: 65px;
		padding-bottom: 65px;
	}
	.inner-sm {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.inner-xs {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.inner-top {
		padding-top: 80px;
	}
	.inner-top-md {
		padding-top: 65px;
	}
	.inner-top-sm {
		padding-top: 50px;
	}
	.inner-top-xs {
		padding-top: 40px;
	}
	.inner-bottom {
		padding-bottom: 80px;
	}
	.inner-bottom-md {
		padding-bottom: 65px;
	}
	.inner-bottom-sm {
		padding-bottom: 50px;
	}
	.inner-bottom-xs {
		padding-bottom: 40px;
	}
}
.section-title {
	font-size: 20px;
	font-family: 'FjallaOneRegular';
	border-bottom: 1px solid #e3e3e3;
	padding-bottom: 10px;
	text-transform: uppercase;
}
.home-owl-carousel .owl-controls, .blog-slider .owl-controls, .brand-slider .owl-controls {
	margin-top: 0px;
}
/*carousel control button*/
.custom-carousel .owl-controls {
	position: absolute;
	right: 0;
	top: -25px;
	width: 100%;
	display: block;
	.owl-prev {
		position: absolute;
		width: 20px;
		height: 20px;
		top: -36px;
		right: 27px;
		-webkit-transition: all linear 0.2s;
		-moz-transition: all linear 0.2s;
		-ms-transition: all linear 0.2s;
		-o-transition: all linear 0.2s;
		transition: all linear 0.2s;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		background: #dddddd;
		&:before {
			color: #fff;
			content: "\f104";
			font-family: fontawesome;
			font-size: 13px;
			left: 8px;
			position: absolute;
			top: 0px;
		}
	}
	.owl-next {
		position: absolute;
		width: 20px;
		height: 20px;
		top: -36px;
		right: 0px;
		-webkit-transition: all linear 0.2s;
		-moz-transition: all linear 0.2s;
		-ms-transition: all linear 0.2s;
		-o-transition: all linear 0.2s;
		transition: all linear 0.2s;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		background: #dddddd;
		&:before {
			content: "\f105";
			font-family: fontawesome;
			color: #fff;
			font-size: 13px;
			left: 8px;
			position: absolute;
			top: 0px;
		}
	}
}
.logo-slider .owl-controls {
	.owl-prev, .owl-next {
		top: -57px;
	}
}
.featured-product {
	margin-bottom: 19px;
}
.logo-slider-inner {
	margin-top: 50px;
	margin-bottom: 60px;
}
.special-product .product {
	&:first-child {
		margin-bottom: 30px;
	}
	&:last-child {
		margin-top: 30px;
	}
}
.best-product .product:first-child {
	margin-bottom: 30px;
}
#owl-main .owl-controls .owl-buttons .icon {
	position: relative;
	top: 8px;
	color: #fff !important;
}
.config-options ul > li > a {
	display: block;
}
.read-more-bottom {
	margin-bottom: 10px;
}
.unicase-form-control {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	border-color: #eee;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	height: auto;
	padding: 10px 12px;
	&:focus {
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		border-color: #d8d8d8;
	}
}
.animate-dropdown .open > .dropdown-menu {
	animation-name: slidenavAnimation;
	animation-duration: 200ms;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
	-webkit-animation-name: slidenavAnimation;
	-webkit-animation-duration: 200ms;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-timing-function: ease-out;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-name: slidenavAnimation;
	-moz-animation-duration: 200ms;
	-moz-animation-iteration-count: 1;
	-moz-animation-timing-function: ease-out;
	-moz-animation-fill-mode: forwards;
	> .dropdown-submenu > .dropdown-menu {
		animation-name: slidenavAnimation;
		animation-duration: 200ms;
		animation-iteration-count: 1;
		animation-timing-function: ease-out;
		animation-fill-mode: forwards;
		-webkit-animation-name: slidenavAnimation;
		-webkit-animation-duration: 200ms;
		-webkit-animation-iteration-count: 1;
		-webkit-animation-timing-function: ease-out;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-name: slidenavAnimation;
		-moz-animation-duration: 200ms;
		-moz-animation-iteration-count: 1;
		-moz-animation-timing-function: ease-out;
		-moz-animation-fill-mode: forwards;
	}
}
@keyframes slidenavAnimation {
	from {
		margin-top: -30px;
		opacity: 0;
	}
	to {
		margin-top: 0;
		opacity: 1;
	}
}
@-webkit-keyframes slidenavAnimation {
	from {
		margin-top: -30px;
		opacity: 0;
	}
	to {
		margin-top: 0;
		opacity: 1;
	}
}
.seller-product .products {
	margin-bottom: 35px;
	.product .product-info .name {
		font-size: 18px;
		margin-top: 5px !important;
	}
}
a {
	color: #12cca7;
}
.show-theme-options {
	&:hover, &:focus {
		background: #12cca7;
	}
}
.btn-primary:hover, .btn-black:hover, .btn-primary:focus, .btn-black:focus {
	background: #12cca7;
}
.top-bar {
	.cnt-account ul > li a {
		&:hover, &:focus {
			color: #12cca7;
		}
	}
	.cnt-block ul li {
		a .caret {
			color: #12cca7;
		}
		.dropdown-menu li a {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
	}
}
.main-header .top-search-holder .contact-row .icon {
	color: #12cca7;
}
.top-cart-row .dropdown-cart {
	.lnk-cart .items-cart-inner .total-price-basket .total-price {
		color: #12cca7;
	}
	.dropdown-menu {
		.cart-item.product-summary {
			.name a {
				&:hover, &:focus {
					color: #12cca7;
				}
			}
			.price {
				color: #12cca7;
			}
			.action a {
				&:hover, &:focus {
					color: #12cca7;
				}
			}
		}
		.cart-total .price {
			color: #12cca7;
		}
	}
}
.main-header .top-search-holder .search-area .categories-filter .dropdown-menu li a {
	&:hover, &:focus {
		color: #12cca7;
	}
}
.header-style-1 .header-nav {
	background: #12cca7;
}
.cnt-home .header-style-1 {
	.header-nav .navbar .navbar-nav > li {
		&.active {
			background: #0b816a;
		}
		> a {
			&:hover, &:focus {
				background: #0b816a;
			}
		}
	}
	&.header-style-2 .header-nav .navbar .navbar-nav > li.active {
		background: #12cca7;
	}
}
.cnt-homepage .header-style-1.header-style-2 .header-nav .navbar .navbar-nav > li.active, .cnt-home .header-style-1.header-style-3 .header-nav .navbar .navbar-nav > li.active {
	background: #12cca7;
}
.header-style-2 .header-nav .navbar-default .navbar-collapse .navbar-nav > li {
	> a {
		&:hover, &:focus {
			background: #12cca7 !important;
		}
	}
	&.open {
		background: #12cca7;
	}
}
.sidebar .side-menu nav .nav > li a {
	&:hover, &:focus, &:hover:after, &:focus:after {
		color: #12cca7;
	}
}
.info-box .info-box-heading.green {
	color: #12cca7;
}
.scroll-tabs .nav-tab-line li a {
	&:hover, &:focus {
		color: #12cca7;
	}
}
.product {
	.product-info {
		.name a {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
		.product-price .price {
			color: #12cca7;
		}
	}
	.cart .action ul li {
		&.lnk a {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
		&.add-cart-button .btn-primary {
			&:hover, &:focus {
				background: #12cca7;
			}
			&.icon {
				&:hover, &:focus {
					background: #0b816a;
				}
			}
		}
	}
}
.tag.sale {
	background: #abd07e;
}
.copyright-bar .copyright a {
	color: #12cca7;
}
.blog-slider-container .blog-slider .blog-post-info .name a {
	&:hover, &:focus {
		color: #12cca7;
	}
}
.footer .links-social .social-icons a {
	&:hover, &:focus, &.active {
		background: #12cca7;
	}
}
.latest-tweet .re-twitter .comment {
	a, .icon .fa-stack-2x {
		color: #12cca7;
	}
}
.footer {
	.contact-information .media {
		.icon .fa-stack-2x {
			color: #12cca7;
		}
		.media-body a {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
	}
	.footer-bottom .module-body ul li a {
		&:hover, &:focus {
			color: #12cca7;
		}
	}
}
.product-tag .item {
	&.active, &:hover, &:focus {
		background: #12cca7;
	}
}
.hot-deals .product-info {
	.product-price .price {
		color: #12cca7;
	}
	.name a {
		&:hover, &:focus {
			color: #12cca7;
		}
	}
}
.custom-carousel .owl-controls {
	.owl-prev:hover, .owl-next:hover, .owl-prev:focus, .owl-next:focus {
		background: #0b816a;
	}
}
.yamm .dropdown-menu {
	.title {
		&:hover, &:focus {
			color: #12cca7;
		}
	}
	li a {
		&:hover, &:focus {
			color: #12cca7;
		}
	}
}
.breadcrumb ul {
	li.active {
		color: #0b816a;
	}
	a {
		&:hover, &:focus {
			color: #0b816a;
		}
	}
}
.filters-container {
	.nav-tabs.nav-tab-box li.active a .icon {
		color: #12cca7;
	}
	.pagination-container ul li {
		&.active a {
			color: #12cca7;
		}
		&.prev:hover, &.next:hover, &.prev:focus, &.next:focus {
			background: #12cca7;
		}
		a {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
	}
	.lbl-cnt .dropdown.dropdown-med .dropdown-menu li a {
		&:hover, &:focus {
			color: #12cca7;
		}
	}
}
.sidebar {
	.sidebar-module-container .sidebar-widget .sidebar-widget-body {
		.accordion .accordion-group .accordion-heading .accordion-toggle {
			color: #12cca7;
			&:hover, &:focus, &:after {
				color: #12cca7;
			}
		}
		.price-range-holder .slider .slider-track .slider-handle {
			border: 5px solid #12cca7;
		}
		.list li a {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
		.compare-report span {
			color: #12cca7;
		}
	}
	.sidebar-widget .advertisement .owl-controls .owl-pagination .owl-page {
		&.active span, &:hover span {
			background: #12cca7;
		}
	}
}
.single-product {
	.gallery-holder .gallery-thumbs .owl-item .item:hover {
		border: 1px solid #12cca7;
	}
	.product-info {
		.rating-reviews .reviews .lnk {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
		.price-container .price-box .price {
			color: #12cca7;
		}
		.quantity-container .cart-quantity .arrows .arrow {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
		.product-social-link .social-icons ul li a {
			&:hover, &:focus {
				background: #12cca7;
			}
		}
	}
	.product-tabs .nav.nav-tabs.nav-tab-cell li {
		a {
			&:hover, &:focus {
				background: #12cca7;
			}
			&:hover:after, &:focus:after {
				border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #12cca7;
			}
		}
		&.active a {
			background: #12cca7;
			&:after {
				border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #12cca7;
			}
		}
	}
}
.cart .action .add-cart-button .btn.btn-primary.icon {
	&:hover, &:focus {
		background: #12cca7;
	}
}
.product-tabs .tab-content .tab-pane .product-reviews .reviews .review {
	.review-title .date span, .author span {
		color: #12cca7;
	}
}
#owl-main {
	&:hover {
		.owl-prev:hover, .owl-next:hover {
			background: #0b816a;
		}
	}
	.owl-controls .owl-pagination .owl-page {
		&:active span, &:hover span {
			background: #12cca7;
		}
	}
}
.cart .action {
	ul .lnk .add-to-cart {
		&:hover, &:focus {
			color: #12cca7 !important;
		}
	}
	.add-to-cart {
		&:hover, &:focus {
			color: #12cca7 !important;
		}
	}
}
.homepage-container {
	.product {
		.tag.hot {
			background: #12cca7;
		}
		.product-info .name a {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
	}
	.btn-primary {
		&:hover, &:focus {
			background: #12cca7;
			border: 2px solid #12cca7;
		}
	}
}
.category-product .cart .action ul li .add-to-cart {
	&:hover, &:focus {
		background: #12cca7;
	}
}
.furniture-container .product .btn-primary {
	&:hover, &:focus {
		background: #12cca7;
	}
}
.header-style-3 .header-nav .navbar-default .navbar-collapse .navbar-nav > li {
	> a {
		&:hover, &:focus {
			background: #12cca7;
		}
	}
	&.open {
		background: #12cca7;
	}
}
#owl-single-product-thumbnails .owl-controls .owl-pagination .owl-page {
	&.active span {
		background: #12cca7 !important;
	}
	span {
		&:hover, &:focus {
			background: #12cca7 !important;
		}
	}
}
.cnt-homepage .sidebar .sidebar-module-container .sidebar-widget .sidebar-widget-body .price-range-holder .slider .slider-track .slider-handle.max-slider-handle {
	background: #12cca7;
}
.blog-page {
	.blog-post h1 a {
		&:hover, &:focus {
			color: #12cca7;
		}
	}
	.sidebar .sidebar-module-container .sidebar-widget .nav-tabs > li.active > a {
		color: #0b816a;
		&:hover, &:focus {
			color: #0b816a;
		}
	}
	.blog-post .social-media a {
		&:hover, &:focus {
			background-color: #12cca7;
		}
	}
	.sidebar .sidebar-module-container {
		.search-area .search-button:after {
			color: #0b816a;
		}
		.sidebar-widget .blog-post h4 a {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
	}
	.blog-post-author-details .author-social-network button .twitter-icon {
		background-color: #12cca7;
	}
	.blog-review .review-action a {
		color: #12cca7;
		&:hover, &:focus {
			color: #12cca7;
		}
	}
	.blog-post-author-details .author-social-network .dropdown-menu > li > a {
		&:hover, &:focus {
			color: #12cca7;
		}
	}
}
.checkout-box {
	.checkout-steps {
		.panel .panel-heading .unicase-checkout-title > a:not(.collapsed) span {
			background-color: #12cca7;
		}
		.checkout-step-01 .already-registered-login a {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
	}
	.checkout-progress-sidebar .panel-body ul li a {
		&:hover, &:focus {
			color: #12cca7;
		}
	}
}
.contact-page .contact-info .contact-i {
	background-color: #12cca7;
}
.shopping-cart {
	.shopping-cart-table table tbody tr {
		.cart-product-name-info {
			h4 a {
				&:hover, &:focus {
					color: #12cca7;
				}
			}
			.cart-product-info span span {
				color: #0b816a;
			}
		}
		.cart-product-edit a {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
	}
	.cart-shopping-total table thead tr th .cart-grand-total {
		color: #0b816a;
	}
}
.shopping-cart-table table tbody tr td .quant-input .arrows .arrow {
	&:hover, &:focus {
		color: #12cca7;
	}
}
.logo-color {
	fill: #12cca7;
}
.cnt-homepage .single-product {
	.product-info-block {
		.form-control .dropdown-menu ul li a {
			&:hover, &:focus {
				color: #12cca7;
			}
		}
		.txt.txt-qty {
			color: #12cca7;
		}
	}
	.product-tabs .nav-tab-cell-detail {
		border-bottom: none;
		li {
			margin-right: 10px;
			padding: 0;
			a {
				&:hover, &:focus {
					background: #12cca7;
					border: 2px solid #12cca7;
				}
			}
			&.active a {
				background: #12cca7;
				border: 2px solid #12cca7;
			}
		}
	}
}
.shopping-cart .estimate-ship-tax table tbody .unicase-form-control .dropdown-menu.open ul li a {
	&:hover, &:focus {
		color: #12cca7;
	}
}
.yamm .dropdown-menu {
	border-top-color: #12cca7;
}
.product-comparison .compare-table tr td {
	.product-price .price, .in-stock {
		color: #12cca7;
	}
}
.body-content .my-wishlist-page .my-wishlist table tbody .product-name a {
	&:hover, &:focus {
		color: #12cca7;
	}
}
.product-comparison .compare-table tr td {
	.product-price .price, .in-stock {
		color: #12cca7;
	}
}
.body-content .x-page .x-text {
	h1, a {
		color: #12cca7;
	}
}
.sign-in-page .register-form .forgot-password {
	color: #12cca7;
	&:hover, &:focus {
		color: #12cca7;
	}
}
.body-content .my-wishlist-page .my-wishlist table tbody .price, .terms-conditions-page .contact-form {
	color: #12cca7;
}
/*===================================================================================*/
/*  GREEN Color
/*===================================================================================*/
/*===================================================================================*/
/*  RED Color
/*===================================================================================*/
/*===================================================================================*/
/*  BLUE Color
/*===================================================================================*/
/*===================================================================================*/
/*  ORANGE Color
/*===================================================================================*/
/*===================================================================================*/
/*  DARK GREEN Color
/*===================================================================================*/
.top-bar {
	background: #f4f4f4;
	padding: 10px 0 9px;
	font-size: 12px;
	.cnt-account {
		float: left;
		padding: 6px 0px;
		ul {
			margin: 0px;
			> li {
				display: inline-block;
				line-height: 12px;
				padding: 3px 12px;
				border-left: 1px solid #e2e2e2;
				&:last-child {
					border-right: 1px solid #e2e2e2;
				}
				a {
					color: #888888;
					padding: 0px;
					font-weight: 400;
					-webkit-transition: all 0.2s linear 0s;
					-moz-transition: all 0.2s linear 0s;
					-o-transition: all 0.2s linear 0s;
					transition: all 0.2s linear 0s;
					.icon {
						display: block;
						float: left;
						padding-right: 6px;
						font-size: 11px;
					}
					&:hover, &:focus {
						text-decoration: none;
					}
				}
			}
		}
	}
	.cnt-block {
		float: right;
		.list-inline {
			margin: 0px;
			> li {
				display: inline-block;
				> a {
					padding: 6px 15px;
					border: 1px solid #d4d4d4;
					-webkit-transition: all 0.2s linear 0s;
					-moz-transition: all 0.2s linear 0s;
					-o-transition: all 0.2s linear 0s;
					transition: all 0.2s linear 0s;
					color: #888;
					display: inline-block;
					.value {
						color: #666;
						margin: 0px 7px;
					}
					&:hover, &:focus {
						text-decoration: none;
						color: #888888;
					}
				}
				.dropdown-menu {
					-webkit-border-radius: 0px;
					-moz-border-radius: 0px;
					border-radius: 0px;
					-moz-box-shadow: none;
					-webkit-box-shadow: none;
					box-shadow: none;
					border: 1px solid #e1e1e1;
					top: 125%;
					left: 5px;
					li a {
						&:hover, &:focus {
							background: rgba(0, 0, 0, 0);
						}
					}
				}
			}
		}
	}
}
.main-header {
	padding: 50px 0px 60px 0px;
	.logo-holder {
		margin-top: 10px;
	}
	.top-search-holder {
		.contact-row {
			line-height: 20px;
			color: #9b9b9b;
			.phone {
				margin: 0 23px 0 0;
				border-right: 1px solid #E2E2E2;
				padding-right: 35px;
			}
			.icon {
				font-size: 18px;
				line-height: 23px;
				margin: 0 8px 0 0;
				vertical-align: middle;
			}
		}
		.search-area {
			border: 1px solid #e2e2e2;
			-webkit-border-radius: 0px;
			-moz-border-radius: 0px;
			border-radius: 0px;
			margin: 8px 0 0;
			.search-field {
				border: medium none;
				-webkit-border-radius: 5px 0 0 5px;
				-moz-border-radius: 5px 0 0 5px;
				border-radius: 5px 0 0 5px;
				padding: 13px;
				width: 67%;
			}
			.categories-filter {
				border-right: 1px solid #E0E0E0;
				text-transform: capitalize;
				display: inline-block;
				line-height: 44px;
				background: #f6f6f6;
				a {
					padding: 0 10px;
					color: #666;
					font-size: 13px;
					.caret {
						margin-left: 15px;
						color: #a0a0a0;
					}
				}
				.dropdown-menu {
					-moz-box-shadow: none;
					-webkit-box-shadow: none;
					box-shadow: none;
					border: 1px solid #e1e1e1;
					padding: 12px 17px;
					-webkit-border-radius: 0px;
					-moz-border-radius: 0px;
					border-radius: 0px;
					li {
						margin-bottom: 10px;
						a {
							padding: 0px;
							&:hover, &:focus {
								background: rgba(0, 0, 0, 0);
							}
						}
						&.menu-header {
							font-family: 'FjallaOneRegular';
							line-height: 30px;
						}
					}
				}
			}
			.search-button {
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				display: inline-block;
				float: right;
				margin: 0px;
				padding: 12px 19px 13px;
				text-align: center;
				background: #f6f6f6;
				border-left: 1px solid #E0E0E0;
				&:after {
					color: #b2b2b2;
					content: "\f002";
					font-family: fontawesome;
					font-size: 13px;
					line-height: 9px;
					vertical-align: middle;
				}
			}
		}
	}
}
.cnt-home .header-style-1.header-style-2 .header-nav .navbar-default {
	background: #404040;
}
.top-cart-row {
	padding-top: 31px;
	.dropdown-cart {
		float: right;
		.lnk-cart {
			padding: 0px;
			border: 1px solid #e1e1e1;
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			border-radius: 0;
			display: inline-block;
			color: #666666;
			.items-cart-inner {
				position: relative;
				.total-price-basket {
					padding: 13px 15px;
					font-family: 'FjallaOneRegular';
					text-transform: uppercase;
					float: left;
				}
				.basket {
					float: right;
					background: #efefef;
					padding: 12px;
					border-left: 1px solid #e1e1e1;
					padding: 13px 15px;
				}
				.basket-item-count {
					-webkit-border-radius: 100px;
					-moz-border-radius: 100px;
					border-radius: 100px;
					height: 21px;
					position: absolute;
					right: 33px;
					top: -13px;
					width: 21px;
					background: #f1c40f;
					color: #fff;
					font-size: 13px;
					text-align: center;
				}
			}
		}
		.dropdown-menu {
			border: 1px solid #e1e1e1;
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			border-radius: 0;
			float: right;
			left: auto;
			min-width: 0;
			padding: 24px 22px;
			right: 0;
			width: 230px;
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
			box-shadow: none;
			.cart-item.product-summary {
				.name {
					font-size: 13px;
					font-family: 'FjallaOneRegular';
					margin-top: 0px;
					a {
						color: #666666;
					}
				}
				.price {
					font-weight: 700;
				}
				.action {
					padding: 0;
					position: relative;
					font-size: 15px;
					right: 8px;
					top: 8px;
					a {
						color: #898989;
					}
				}
			}
			.cart-total {
				.text {
					font-size: 13px;
					font-family: 'FjallaOneRegular';
					color: #666666;
					margin-right: 10px;
				}
				.price {
					font-weight: 700;
				}
			}
		}
	}
}
.header-style-1 .header-nav .navbar-default {
	border: medium none;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	background: rgba(0, 0, 0, 0);
	margin: 0;
	.navbar-collapse {
		padding: 0;
		.navbar-nav > li {
			&.active > a {
				color: #fff;
			}
			> a {
				background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
				color: #FFFFFF;
				font-family: 'FjallaOneRegular';
				font-size: 20px;
				line-height: 20px;
				padding: 21px 29px;
				text-transform: uppercase;
				-webkit-transitio: all 0.2s linear 0s;
				-moz-transition: all 0.2s linear 0s;
				-o-transition: all 0.2s linear 0s;
				transition: all 0.2s linear 0s;
			}
			.menu-label {
				position: absolute;
				text-transform: uppercase;
				top: -14px;
				display: inline;
				padding: 1px 7px;
				color: #fff;
				font-size: 11px;
				font-family: 'Roboto';
				right: 23px;
				&:after {
					border-width: 7.5px 8.5px 0 7.5px;
					right: 18px;
					top: 90%;
					border-style: solid;
					content: "";
					display: block;
					height: 0;
					position: absolute;
					-webkit-transition: all 0.3s ease 0s;
					-moz-transition: all 0.3s ease 0s;
					-o-transitio: all 0.3s ease 0s;
					transition: all 0.3s ease 0s;
					width: 0;
					z-index: 100;
				}
				&:before {
					right: 18px;
					top: 90%;
				}
				&.new-menu {
					background: #f1c40f;
					&:after, &:before {
						border-color: #f1c40f rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
					}
				}
				&.hot-menu {
					background: #ff7878;
					&:after, &:before {
						border-color: #ff7878 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
					}
				}
			}
		}
	}
}
.header-style-2 {
	/*margin-bottom:10px;*/
	.header-nav {
		background: rgba(0, 0, 0, 0) !important;
		.navbar-default .nav-bg-class {
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			border-radius: 0;
			margin: 0;
			.navbar-collapse .navbar-nav > li {
				> a {
					color: #555;
					&:hover, &:focus {
						color: #fff;
					}
				}
				&.open > a {
					color: #fff;
				}
			}
		}
	}
}
.header-style-3 .header-nav {
	background: #202020 !important;
	border: medium none;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	margin: 0;
	.navbar-default .navbar-collapse .navbar-nav > li > a {
		color: #fff;
		&:hover, &:focus {
			color: #fff;
		}
	}
	.yamm .dropdown-menu {
		top: 62px;
	}
}
.yamm {
	.nav, .collapse, .dropup, .dropdown {
		position: static;
	}
	.dropdown-menu {
		left: auto;
		top: 110%;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		border: 1px solid #f1f1f1;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		.yamm-content {
			padding: 20px 15px;
			.links li a {
				padding: 8px 0px;
			}
		}
		h2 {
			font-size: 15px;
			line-height: 27px;
			color: #555;
			text-transform: uppercase;
			font-family: 'FjallaOneRegular';
			margin-top: 0px;
		}
		li {
			line-height: 26px;
			padding: 2px 0px;
			-webkit-transition: all 0.3s ease 0s;
			-moz-transition: all 0.3s ease 0s;
			-o-transition: all 0.3s ease 0s;
			transition: all 0.3s ease 0s;
			a {
				color: #3D3D3D;
				line-height: 26px;
				text-transform: none;
				display: block;
				padding: 8px 16px;
				&:hover, &:focus {
					background: rgba(0, 0, 0, 0);
					margin-left: 5px;
				}
			}
		}
		.text {
			font-size: 13px;
			line-height: 20px;
			position: relative;
			bottom: 0px;
			top: 73px;
		}
	}
	.dropdown.yamm-fw .dropdown-menu {
		left: 0;
		right: 0;
	}
}
.cnt-home .header-style-1.header-style-2 .header-nav .navbar .navbar-nav > li.active > a, .cnt-homepage .header-style-1.header-style-2 .header-nav .navbar .navbar-nav > li.active > a {
	color: #fff;
}
.cnt-home .header-style-1.header-style-2 .header-nav .navbar-default {
	background: #404040;
	.nav-bg-class {
		border: none;
		.navbar-collapse .navbar-nav > li > a {
			color: #fff;
		}
	}
}
.cnt-homepage .header-style-2 .header-nav .navbar-default {
	background: #fff;
	.nav-bg-class {
		-moz-box-shadow: 0 0 0 3px #F6F6F6 inset;
		-webkit-box-shadow: 0 0 0 3px #F6F6F6 inset;
		box-shadow: 0 0 0 3px #F6F6F6 inset;
	}
}
.header-nav .navbar-default .dropdown .dropdown-menu.pages .links > li {
	border-bottom: 1px solid #E0E0E0;
	padding: 5px 0;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	&:last-child {
		border-bottom: none;
	}
	> a {
		line-height: 26px;
		padding: 0px;
		&:hover, &:focus {
			margin-left: 0px;
		}
	}
}
.sidebar {
	.side-menu {
		.head {
			-webkit-border-radius: 0px;
			-moz-border-radius: 0px;
			border-radius: 0px;
			color: #FFFFFF;
			font-size: 18px;
			font-family: 'FjallaOneRegular';
			padding: 15px 17px;
			text-transform: uppercase;
			background: #333333;
			.icon {
				margin-right: 20px;
			}
		}
		nav .nav > li {
			-moz-border-bottom-colors: none;
			-moz-border-left-colors: none;
			-moz-border-right-colors: none;
			-moz-border-top-colors: none;
			border-color: -moz-use-text-color #ebebeb;
			border-image: none;
			border-left: 1px solid #ebebeb;
			border-right: 1px solid #ebebeb;
			border-style: none solid;
			border-width: 0 1px;
			-webkit-transition: all 0.3s ease 0s;
			-moz-transition: all 0.3s ease 0s;
			-o-transition: all 0.3s ease 0s;
			transition: all 0.3s ease 0s;
			position: relative;
			width: 100%;
			border-bottom: 1px solid #e8e8e8;
			background: #f7f7f7;
			> {
				a {
					padding: 13px 15px;
					color: #666666;
					text-transform: uppercase;
					&:after {
						color: #bababa;
						content: "\f0a9";
						float: right;
						font-size: 12px;
						height: 20px;
						line-height: 18px;
						-webkit-transition: all 0.3s ease 0s;
						-moz-transition: all 0.3s ease 0s;
						-o-transition: all 0.3s ease 0s;
						transition: all 0.3s ease 0s;
						width: 20px;
						font-family: FontAwesome;
					}
					.icon {
						font-size: 20px;
						margin-right: 25px;
					}
					&:hover, &:focus {
						background: #fff;
						border-left: 5px solid #abd07e;
					}
					&:hover .icon, &:focus .icon {
						color: #666666;
					}
				}
				.mega-menu {
					padding: 3px 0;
					top: 0 !important;
					left: 100%;
					margin: 0;
					min-width: 330%;
					/*338%;*/
					position: absolute;
					top: 0px;
					-webkit-border-radius: 0px;
					-moz-border-radius: 0px;
					border-radius: 0px;
					.yamm-content {
						padding: 10px 20px;
						ul > li {
							border-bottom: 1px solid #E0E0E0;
							padding: 5px 0;
							-webkit-transition: all 0.3s ease 0s;
							-moz-transition: all 0.3s ease 0s;
							-o-transition: all 0.3s ease 0s;
							transition: all 0.3s ease 0s;
							&:last-child {
								border-bottom: none;
							}
							> a {
								line-height: 26px;
								padding: 0px;
							}
						}
						.dropdown-banner-holder {
							position: absolute;
							right: -36px;
							top: -8px;
						}
					}
				}
			}
		}
	}
	.side-menu2 nav .nav li a {
		padding: 14.3px 15px;
	}
	.sidebar-module-container .sidebar-widget {
		.widget-header {
			background: #eeeeee;
			padding: 10px 15px;
			.widget-title {
				font-size: 14px;
				font-family: 'FjallaOneRegular';
				margin: 0px;
			}
		}
		.sidebar-widget-body {
			.accordion .accordion-group {
				.accordion-heading .accordion-toggle {
					clear: both;
					display: block;
					font-family: 'Roboto', sans-serif;
					font-size: 13px;
					font-weight: 300;
					line-height: 36px;
					&:after {
						content: "\f147";
						float: right;
						font-family: fontawesome;
					}
					&.collapsed {
						color: #666666;
						&:after {
							color: #636363;
							content: "\f196";
							font-family: fontawesome;
						}
					}
				}
				.accordion-body .accordion-inner {
					margin: 14px 0 20px;
					ul {
						padding-left: 15px;
						li {
							line-height: 27px;
							a {
								color: #666666;
								&:before {
									content: "\f105";
									font-family: fontawesome;
									font-size: 14px;
									line-height: 15px;
									margin: 0 5px 0 0;
									-webkit-transition: all 0.3s ease 0s;
									-moz-transition: all 0.3s ease 0s;
									-o-transition: all 0.3s ease 0s;
									transition: all 0.3s ease 0s;
								}
								&:hover:before {
									margin: 0 8px 0 0;
								}
							}
						}
					}
				}
			}
			.price-range-holder {
				padding: 0 0 20px;
				position: relative;
				.slider {
					display: inline-block;
					position: relative;
					vertical-align: middle;
					margin-top: 15px;
					&.slider-horizontal {
						height: 20px;
						width: 100% !important;
					}
					.slider-track {
						background-color: #f1f1f1;
						background-repeat: repeat-x;
						cursor: pointer;
						position: absolute;
						width: 94% !important;
						height: 6px;
						left: 0;
						margin-top: -5px;
						top: 50%;
						width: 100%;
						.slider-selection {
							bottom: 0;
							height: 100%;
							top: 0;
							background-repeat: repeat-x;
							box-sizing: border-box;
							position: absolute;
							background: #c3c3c3;
						}
						.slider-handle {
							background-color: #FFFFFF;
							background-repeat: repeat-x;
							-webkit-border-radius: 400px;
							-moz-border-radius: 400px;
							border-radius: 400px;
							height: 20px;
							margin-left: -3px !important;
							opacity: 1;
							position: absolute;
							top: -3px;
							width: 20px;
							margin-top: -5px;
						}
					}
					.tooltip {
						margin-top: -36px;
					}
				}
				.min-max {
					font-size: 15px;
					font-weight: 700;
					color: #fe5252;
					margin-top: 15px;
				}
			}
			.list li {
				clear: both;
				display: block;
				font-family: 'Roboto', sans-serif;
				font-size: 13px;
				font-weight: 300;
				line-height: 36px;
				a {
					color: #666666;
					display: block;
				}
			}
			.compare-report {
				margin-top: 20px;
				margin-bottom: 30px;
			}
		}
	}
	.sidebar-widget .advertisement {
		.item {
			background-color: #e7e7e7;
			background-position: center 55%;
			background-size: cover;
			height: 430px;
			.caption {
				color: #636363;
				left: 12%;
				letter-spacing: -3px;
				position: absolute;
				top: 11%;
				z-index: 100;
				display: table-cell;
				.big-text {
					font-size: 60px;
					line-height: 125px;
					text-transform: uppercase;
					font-family: 'FjallaOneRegular';
					color: #fff;
					text-shadow: 1px 1px 3px #cfcfcf;
					.big {
						font-size: 120px;
						color: #ff7878;
						display: block;
						text-shadow: 1px 1px 3px #cfcfcf;
					}
				}
				.excerpt {
					font-size: 24px;
					letter-spacing: -1px;
					text-transform: uppercase;
					color: #e6e6e6;
					text-shadow: 1px 1px 3px #cfcfcf;
				}
			}
		}
		.owl-controls {
			bottom: 20px;
			position: absolute;
			text-align: center;
			top: auto;
			width: 100%;
			.owl-pagination {
				display: inline-block;
				.owl-page {
					display: inline-block;
					span {
						display: block;
						width: 15px;
						height: 15px;
						background: #fff;
						border: none;
						-webkit-border-radius: 50%;
						-moz-border-radius: 50%;
						border-radius: 50%;
						margin: 0 5px;
						-webkit-transition: all 200ms ease-out;
						-moz-transition: all 200ms ease-out;
						-o-transition: all 200ms ease-out;
						transition: all 200ms ease-out;
					}
				}
			}
		}
	}
}
.related-product .tag {
	font-size: 15px;
	font-weight: 700;
	height: 50px;
	line-height: 45px;
	left: 15px;
	text-align: center;
	top: 3.5%;
	width: 50px;
	position: absolute;
}
/*===================================================================================*/
/*  OWL CAROUSEL
/*===================================================================================*/
#owl-main {
	text-align: center;
	cursor: default;
	height: 368px;
	.owl-controls {
		display: inline-block;
		position: relative;
		margin-top: 40px;
	}
	.owl-pagination {
		position: relative;
		line-height: 30px;
	}
	.owl-buttons {
		display: block;
	}
	.owl-prev, .owl-next {
		display: inline-block;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 30px;
		height: 50px;
		font-size: 21px;
		background-color: #f2f2f2;
		border: none;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		margin: auto;
		-webkit-transition: all 200ms ease-out;
		-moz-transition: all 200ms ease-out;
		-o-transition: all 200ms ease-out;
		transition: all 200ms ease-out;
		opacity: 0;
	}
	.owl-prev {
		left: 20px;
		/* -35px;*/
	}
	.owl-next {
		right: 20px;
	}
	&:hover {
		.owl-prev {
			left: 0px;
			opacity: 0.5;
		}
		.owl-next {
			right: 0px;
			opacity: 0.5;
		}
		.owl-prev:hover, .owl-next:hover {
			opacity: 1;
		}
	}
	.owl-outer-nav {
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: -15%;
			width: 130%;
			height: 100%;
			z-index: 0;
		}
		.owl-wrapper-outer, .owl-pagination, .owl-prev, .owl-next {
			z-index: 1;
		}
		.owl-controls {
			position: static;
		}
		.owl-prev {
			left: -65px;
		}
		.owl-next {
			right: -65px;
		}
		&:hover {
			.owl-prev {
				left: -80px;
			}
			.owl-next {
				right: -80px;
			}
		}
	}
	.owl-ui-md {
		.owl-pagination {
			line-height: 45px;
		}
		.owl-prev, .owl-next {
			width: 45px;
			height: 45px;
			font-size: 45px;
		}
		.owl-prev {
			left: -55px;
		}
		.owl-next {
			right: -55px;
		}
		&:hover {
			.owl-prev {
				left: -60px;
			}
			.owl-next {
				right: -60px;
			}
		}
	}
	.owl-outer-nav.owl-ui-md {
		&:after {
			left: -18%;
			width: 136%;
		}
		.owl-prev {
			left: -85px;
		}
		.owl-next {
			right: -85px;
		}
		&:hover {
			.owl-prev {
				left: -100px;
			}
			.owl-next {
				right: -100px;
			}
		}
	}
	.owl-ui-lg {
		.owl-pagination {
			line-height: 60px;
		}
		.owl-prev, .owl-next {
			width: 60px;
			height: 60px;
			font-size: 42px;
		}
		.owl-prev {
			left: -75px;
		}
		.owl-next {
			right: -75px;
		}
		&:hover {
			.owl-prev {
				left: -80px;
			}
			.owl-next {
				right: -80px;
			}
		}
	}
	.owl-outer-nav.owl-ui-lg {
		&:after {
			left: -22%;
			width: 144%;
		}
		.owl-prev {
			left: -105px;
		}
	}
}
/*.panel-group .panel .owl-controls {
	margin-top: 25px;
}
.panel-group.blank .panel .owl-controls {
	margin-top: 40px;
}*/
.owl-outer-nav.owl-ui-lg .owl-next {
	right: -105px;
}
#owl-main {
	.owl-outer-nav.owl-ui-lg:hover {
		.owl-prev {
			left: -120px;
		}
		.owl-next {
			right: -120px;
		}
	}
	.owl-inner-nav {
		.owl-controls {
			position: static;
		}
		.owl-prev {
			left: 45px;
		}
		.owl-next {
			right: 45px;
		}
		&:hover {
			.owl-prev {
				left: 30px;
			}
			.owl-next {
				right: 30px;
			}
		}
	}
	.owl-outer-nav {
		.owl-prev, .owl-next {
			bottom: 70px;
		}
	}
	.owl-inner-nav {
		.owl-prev, .owl-next {
			bottom: 70px;
		}
	}
	.owl-outer-nav.owl-ui-md {
		.owl-prev, .owl-next {
			bottom: 85px;
		}
	}
	.owl-inner-nav.owl-ui-md {
		.owl-prev, .owl-next {
			bottom: 85px;
		}
	}
	.owl-outer-nav.owl-ui-lg {
		.owl-prev, .owl-next {
			bottom: 100px;
		}
	}
	.owl-inner-nav.owl-ui-lg {
		.owl-prev, .owl-next {
			bottom: 100px;
		}
	}
	.owl-inner-pagination {
		.owl-pagination, .owl-prev, .owl-next {
			margin-top: -40px;
			top: -60px;
		}
		&.owl-ui-md {
			.owl-pagination, .owl-prev, .owl-next {
				margin-top: -50px;
				top: -65px;
			}
		}
		&.owl-ui-lg {
			.owl-pagination, .owl-prev, .owl-next {
				margin-top: -60px;
				top: -75px;
			}
		}
		&.owl-outer-nav {
			.owl-prev, .owl-next {
				margin: auto;
				top: 0;
				bottom: 43px;
			}
		}
		&.owl-inner-nav {
			.owl-prev, .owl-next {
				margin: auto;
				top: 0;
				bottom: 43px;
			}
		}
		.owl-pagination {
			-webkit-transition: all 200ms ease-out;
			-moz-transition: all 200ms ease-out;
			-o-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
			opacity: 0;
		}
		&:hover .owl-pagination {
			opacity: 1;
		}
		&.owl-inner-nav .owl-pagination {
			top: -45px;
		}
	}
}
.owl-inner-pagination {
	&.owl-outer-nav .owl-pagination {
		top: -45px;
	}
	&.owl-inner-nav.owl-ui-md .owl-pagination, &.owl-outer-nav.owl-ui-md .owl-pagination {
		top: -50px;
	}
	&.owl-inner-nav.owl-ui-lg .owl-pagination, &.owl-outer-nav.owl-ui-lg .owl-pagination, &.owl-inner-nav:hover .owl-pagination, &.owl-outer-nav:hover .owl-pagination {
		top: -60px;
	}
	&.owl-inner-nav.owl-ui-md:hover .owl-pagination, &.owl-outer-nav.owl-ui-md:hover .owl-pagination {
		top: -65px;
	}
	&.owl-inner-nav.owl-ui-lg:hover .owl-pagination, &.owl-outer-nav.owl-ui-lg:hover .owl-pagination {
		top: -75px;
	}
}
#owl-main {
	&.height-md .item {
		height: 457px;
	}
	&.height-lg .item {
		height: 675px;
	}
	.container {
		display: table;
		height: inherit;
	}
	.caption {
		display: table-cell;
		&.vertical-center {
			vertical-align: middle;
			padding-bottom: 3vh;
		}
		&.vertical-top {
			vertical-align: top;
			padding-top: 8vh;
		}
		&.vertical-bottom {
			vertical-align: bottom;
			padding-bottom: 14vh;
		}
		&.text-center {
			padding-left: 10%;
			padding-right: 10%;
		}
		&.text-left {
			padding-right: 20%;
		}
		&.text-right {
			padding-left: 20%;
		}
	}
	.owl-controls {
		display: block;
		position: static;
		margin-top: -47px;
	}
	.owl-pagination {
		background: #FFF;
		line-height: inherit;
		position: relative;
		bottom: -40px;
		padding: 10px;
		display: inline-block;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		margin: auto;
		opacity: 0;
		-webkit-transition: all 200ms ease-out;
		-moz-transition: all 200ms ease-out;
		-o-transition: all 200ms ease-out;
		transition: all 200ms ease-out;
	}
	&:hover .owl-pagination {
		bottom: -25px;
		opacity: 1;
	}
	.owl-prev, .owl-next {
		bottom: 0;
	}
	.owl-controls .owl-page {
		display: inline-block;
	}
	.owl-pagination .owl-page span {
		display: block;
		width: 15px;
		height: 15px;
		background: #d3d3d3;
		border: none;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		margin: 0 5px;
		-webkit-transition: all 200ms ease-out;
		-moz-transition: all 200ms ease-out;
		-o-transition: all 200ms ease-out;
		transition: all 200ms ease-out;
	}
	.owl-item-gap .item {
		margin: 0 15px;
	}
	.owl-item-gap-sm .item {
		margin: 0 10px;
	}
	.owl-item.loading {
		min-height: inherit;
		background: none;
	}
	.item {
		background-color: #FFFFFF;
		background-position: center 55%;
		background-size: cover;
		height: 368px;
		.caption {
			color: #636363;
			right: 0;
			position: absolute;
			top: 24%;
			z-index: 100;
			padding-right: 8%;
			.big-text {
				font-size: 35px;
				line-height: 50px;
				text-transform: uppercase;
				font-family: 'FjallaOneRegular';
				padding: 5px 25px;
				background: #bbbbbb;
				color: #fff;
				.highlight {
					color: #f1c40f;
				}
			}
			.excerpt, .small {
				font-size: 16px;
				/*line-height: 50px;*/
				margin-top: 10px;
				font-family: 'Roboto';
				font-weight: 500;
				padding-left: 23px;
				background: rgba(0, 0, 0, 0);
				color: #fff;
			}
			.excerpt span, .small span {
				background: none repeat scroll 0 0 #bbbbbb;
				-moz-box-shadow: -1.4em 0 0 #bbbbbb, 1.4em 0 0 #bbbbbb;
				-webkit-box-shadow: -1.4em 0 0 #bbbbbb, 1.4em 0 0 #bbbbbb;
				box-shadow: -1.4em 0 0 #bbbbbb, 1.4em 0 0 #bbbbbb;
				line-height: 336%;
				padding: 0.6em 0;
				display: inline;
			}
			.button-holder {
				margin: 10px 0 0;
			}
			&.bg-color {
				padding-right: 3%;
				.big-text {
					background: rgba(0, 0, 0, 0);
					padding: 0px;
					color: #000;
				}
				.excerpt, .small {
					background: rgba(0, 0, 0, 0);
					padding: 0px;
					color: #000;
					margin-top: 0px;
				}
				.excerpt span, .small span {
					background: rgba(0, 0, 0, 0);
					-moz-box-shadow: none;
					-webkit-box-shadow: none;
					box-shadow: none;
					display: block;
					line-height: 24px;
				}
			}
		}
	}
	.full-width-slider {
		margin-bottom: 30px;
		.item {
			background-color: #FFFFFF;
			background-position: center 55%;
			background-size: cover;
			height: 449px;
			.caption {
				position: absolute;
				top: 11%;
				z-index: 100;
				.big-text {
					font-size: 119px;
					line-height: 80px;
					font-family: 'PacificoRegular';
					color: #fff;
					background: rgba(0, 0, 0, 0);
					padding: 0px;
					text-transform: none;
				}
				.excerpt {
					font-size: 60px;
					line-height: 80px;
					margin-top: 15px;
					font-family: 'LatoBold';
					text-transform: uppercase;
					color: #fff;
					background: rgba(0, 0, 0, 0);
					padding: 0px;
				}
				.button-holder {
					margin: 31px 0 0;
				}
			}
		}
	}
}
.cnt-homepage {
	.homepage-slider2 {
		height: 449px;
		#owl-main {
			.item .caption {
				top: 24%;
				padding-right: 10%;
				right: 0px;
				left: 0px;
			}
			.owl-controls {
				margin-top: -89px;
				text-align: center !important;
			}
		}
	}
	.breadcrumb ul {
		text-align: left;
	}
	.cart .action .left {
		margin-left: 10px;
		padding: 2px 10px;
	}
	.sidebar .sidebar-module-container .sidebar-widget .sidebar-widget-body .price-range-holder .slider .slider-track .slider-handle {
		height: 15px;
		width: 15px;
		top: 0px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		border: 2px solid #e4e4e4;
	}
}
.homepage-slider3 {
	height: 368px;
	#owl-main {
		.item {
			height: 368px;
			.caption {
				padding-left: 10%;
				padding-right: 44%;
				.small {
					font-size: 15px;
					line-height: 50px;
					text-transform: uppercase;
					font-family: 'FjallaOneRegular';
					text-shadow: 2px 2px #c3c3c3;
					color: #fff;
					padding-left: 0px;
				}
				.big-text {
					font-size: 45px;
					line-height: 50px;
					text-transform: uppercase;
					font-family: 'FjallaOneRegular';
					text-shadow: 2px 2px #c3c3c3;
					color: #000;
				}
				.excerpt {
					font-size: 13px;
					line-height: 20px;
					color: #fff;
					text-transform: none;
				}
			}
		}
		.owl-controls {
			margin-top: -79px;
		}
	}
}
.home-page-slider4 {
	position: relative;
	.customNavigation {
		position: absolute;
		top: 50%;
		width: 100%;
		margin-top: -15px;
		.controls {
			position: relative;
		}
	}
	.owl-controls {
		bottom: 20px;
		position: absolute;
		text-align: center;
		top: auto;
		width: 100%;
		.owl-buttons {
			display: none !important;
		}
		.owl-pagination .owl-page {
			display: inline-block;
			span {
				background: none repeat scroll 0 0 #e6e6e6;
				border: medium none;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;
				display: block;
				height: 12px;
				margin: 0 5px;
				-webkit-transition: all 200ms ease-out 0s;
				-moz-transition: all 200ms ease-out 0s;
				-o-transition: all 200ms ease-out 0s;
				transition: all 200ms ease-out 0s;
				width: 12px;
				-moz-box-shadow: 1px 3px rgba(0, 0, 0, 0.1) inset;
				-webkit-box-shadow: 1px 3px rgba(0, 0, 0, 0.1) inset;
				box-shadow: 1px 3px rgba(0, 0, 0, 0.1) inset;
			}
		}
	}
	.owl-prev, .owl-next {
		position: absolute;
		-webkit-transition: all 200ms ease-out;
		-moz-transition: all 200ms ease-out;
		-o-transition: all 200ms ease-out;
		transition: all 200ms ease-out;
		opacity: 0;
	}
	.owl-prev {
		left: 20px;
	}
	.owl-next {
		right: 20px;
	}
	&:hover {
		.owl-prev {
			left: 0px;
			opacity: 1;
		}
		.owl-next {
			right: 0px;
			opacity: 1;
		}
		.owl-prev:hover, .owl-next:hover {
			opacity: 1;
		}
	}
	#owl-main .owl-pagination {
		background: rgba(0, 0, 0, 0);
		-webkit-border-radius: 3px;
		-moz-border-radiu: 3px;
		border-radius: 3px;
		display: inline-block;
		line-height: inherit;
		margin: auto;
		opacity: 1;
		padding: 10px;
		position: relative;
		-webkit-transition: all 200ms ease-out 0s;
		-moz-transition: all 200ms ease-out 0s;
		-o-transition: all 200ms ease-out 0s;
		transition: all 200ms ease-out 0s;
		bottom: none;
		bottom: 0px;
	}
}
.cnt-homepage .homepage-container {
	.btn-primary {
		padding: 2px 17px;
		-webkit-border-radius: 0px;
		-moz-border-radiu: 0px;
		border-radius: 0px;
	}
	#owl-main {
		.owl-prev, .owl-next {
			top: 25%;
		}
	}
}
.info-boxes {
	margin-top: 30px;
	.info-box {
		border: 5px solid #f2f2f0;
		padding: 12px 13px;
		.icon {
			font-size: 35px;
		}
		.info-box-heading {
			font-size: 35px;
			line-height: 30px;
			text-transform: uppercase;
			font-family: 'BebasNeueBold';
			margin-top: 5px;
			&.orange {
				color: #ffb847;
			}
			&.red {
				color: #ff7878;
			}
		}
		.text {
			color: #666666;
			text-transform: uppercase;
			font-weight: 500;
			font-size: 13px;
			margin: 0px;
		}
	}
}
.scroll-tabs {
	margin-bottom: 19px;
	.more-info-tab {
		border-bottom: 1px solid #e3e3e3;
		padding-bottom: 10px;
		.new-product-title {
			margin-bottom: 0px;
			margin-top: 5px;
			font-size: 20px;
			font-family: 'FjallaOneRegular';
			text-transform: uppercase;
		}
	}
	.nav-tab-line {
		border-bottom: none;
		margin-top: 7px;
		margin-right: 55px;
		li {
			&.active a {
				border: none;
			}
			a {
				text-transform: uppercase;
				font-weight: 500;
				color: #666666;
				font-size: 14px;
				border: medium none;
				-moz-box-shadow: none;
				-webkit-box-shadow: none;
				box-shadow: none;
				padding: 0 15px !important;
				position: relative;
				&:hover, &:focus {
					background: rgba(0, 0, 0, 0);
				}
				&:hover:after {
					content: "\f0d7";
					font-family: FontAwesome;
					position: absolute;
					right: 50%;
					top: 100%;
					height: 100%;
					margin-right: -4px;
					margin-top: -4px;
					width: 10px;
					-webkit-transition: all 0.2s linear 0s;
					-moz-transition: all 0.2s linear 0s;
					-o-transition: all 0.2s linear 0s;
					transition: all 0.2s linear 0s;
				}
			}
		}
	}
}
.product {
	position: relative;
	.product-image .tag {
		position: absolute;
	}
	.product-info {
		.name {
			font-size: 16px;
			font-family: 'FjallaOneRegular';
			a {
				color: #555;
			}
		}
		.star-rating .color {
			color: #ffb400;
		}
		.product-price {
			.price {
				font-weight: 700;
				font-size: 16px;
				line-height: 30px;
				margin-right: 8px;
			}
			.price-before-discount {
				text-decoration: line-through;
				color: #d3d3d3;
				font-weight: 400;
				line-height: 30px;
				font-size: 14px;
			}
		}
	}
	.cart {
		margin-top: 5px;
		opacity: 0;
		-webkit-transition: all 0.2s linear 0s;
		-moz-transition: all 0.2s linear 0s;
		-o-transition: all 0.2s linear 0s;
		transition: all 0.2s linear 0s;
		width: 100%;
		z-index: 666;
		left: 0px;
		.action ul li {
			float: left;
			&.add-cart-button .btn.btn-primary.icon {
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
				background: #575757;
			}
			&.lnk {
				margin: 10px 0px;
				a {
					padding: 0 10px;
					color: #dadada;
				}
				&.wishlist {
					border-right: 1px solid #dadada;
				}
			}
		}
	}
}
.tag {
	font-size: 13px;
	font-weight: 700;
	line-height: 38px;
	width: 40px;
	height: 40px;
	text-transform: uppercase;
	top: 2.5%;
	z-index: 100;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	color: #fff;
	right: 25px;
	/*11px;*/
	text-align: center;
	span {
		position: relative;
		z-index: 100;
	}
	&.new {
		background: #46aad7;
	}
	&.hot {
		background: #ff7878;
	}
}
.product:hover .cart {
	opacity: 1;
}
.best-seller .product .product-info .name, .special-offer .product .product-info .name {
	margin-top: 4px;
}
.cart {
	margin-top: 5px;
	opacity: 1;
	-webkit-transition: all 0.2s linear 0s;
	-moz-transition: all 0.2s linear 0s;
	-o-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
	width: 100%;
	z-index: 666;
	left: 0px;
	.action {
		float: left;
		.add-cart-button .btn.btn-primary.icon {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			background: #575757;
		}
		&.lnk {
			margin: 10px 0px;
			a {
				padding: 0 10px;
				color: #dadada;
			}
			&.wishlist {
				border-right: 1px solid #dadada;
			}
		}
	}
}
.product-micro .product-image .image a .zoom-overlay {
	&:before {
		color: #FFFFFF;
		content: "\f00e";
		font-family: fontawesome;
		left: 45%;
		position: relative;
		right: 40%;
	}
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	-webkit-transition: all 0.2s linear 0s;
	-moz-transition: all 0.2s linear 0s;
	-o-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
	width: 100%;
	z-index: 99;
	&:hover, &:focus {
		opacity: 1;
	}
}
.cnt-home .product-micro {
	.tag-micro {
		width: 30px;
		height: 30px;
		font-size: 11px;
		line-height: 29px;
		top: 5.5%;
	}
	.product-info {
		.name {
			font-size: 15px;
			margin-top: 0px !important;
		}
		.product-price .price {
			font-size: 15px;
		}
		.action .lnk.btn.btn-primary {
			font-size: 12px;
			line-height: 1.7;
		}
	}
}
.copyright-bar {
	background: #121212;
	padding: 20px 0px;
	.copyright {
		color: #5d5c5c;
		line-height: 28px;
	}
	.payment-methods {
		text-align: right;
		ul li {
			display: inline-block;
			margin-right: 15px;
			&:last-child {
				margin-right: 0px;
			}
		}
	}
	.no-padding {
		padding: 0px;
	}
}
.wide-banner {
	overflow: hidden;
	.image img {
		display: block;
		width: 100%;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
	&:hover img {
		-webkit-transform: rotate(-1deg) scale(1.1);
		-moz-transform: rotate(-1deg) scale(1.1);
		-ms-transform: rotate(-1deg) scale(1.1);
		-o-transform: rotate(-1deg) scale(1.1);
		opacity: 0.9;
	}
}
.cnt-strip {
	position: relative;
	.strip {
		position: absolute;
		bottom: 15px;
		width: 100%;
		padding: 8px 30px 5px 30px;
		h1 {
			font-size: 50px;
			color: #ff4c4c;
			margin: 0;
			text-transform: uppercase;
			font-family: 'BebasNeueBold';
		}
		h2 {
			font-size: 45px;
			color: #f39c12;
			margin: 0;
			text-transform: uppercase;
			font-family: 'BebasNeueBold';
			.shopping-needs {
				font-family: 'BebasNeueRegular';
				color: #434343;
			}
		}
		h3 {
			font-size: 30px;
			color: #434343;
			margin: 0;
			text-transform: uppercase;
			font-family: 'BebasNeueRegular';
		}
		h4 {
			font-size: 20px;
			margin: 0;
			text-transform: uppercase;
			font-family: 'BebasNeueRegular';
		}
		&.strip-text {
			width: 60%;
		}
		.red {
			color: #ff7878;
		}
		.black {
			color: #434343;
		}
		.green {
			color: #83c038;
		}
		.white {
			color: #fff;
		}
		.normal-shopping-needs {
			font-size: 35px;
			font-family: 'Roboto';
		}
	}
	.new-label {
		background: url("../images/label.png") no-repeat scroll right top;
		height: 72px;
		position: absolute;
		left: -1px;
		top: -1px;
		width: 72px;
		z-index: 999;
		.text {
			color: #fff;
			font-family: 'Roboto', sans-serif;
			font-size: 18px;
			font-weight: normal;
			line-height: 22px;
			left: -13px;
			position: absolute;
			text-align: center;
			top: 14px;
			-ms-transform: rotate(-43deg);
			/* IE 9 */
			-webkit-transform: rotate(-43deg);
			/* Chrome, Safari, Opera */
			transform: rotate(-43deg);
			width: 100%;
		}
	}
}
.wide-banners {
	margin-top: -10px;
	&.homepage-banner {
		margin-top: 30px;
		h4 {
			font-size: 30px;
			font-family: 'FjallaOneRegular';
		}
		h3 {
			font-size: 35px;
			font-family: 'FjallaOneRegular';
		}
	}
}
.new-banner {
	margin-top: 30px;
	.cnt-strip .strip {
		width: auto;
		padding: 0px;
		right: 23px;
		h3 {
			background: #707070;
			padding: 13px 23px 13px 16px;
			line-height: 24px;
			font-size: 30px;
			font-family: 'FjallaOneRegular';
		}
		h5 {
			font-size: 16px;
			line-height: 30px;
			font-weight: 700;
			padding-right: 21px;
			text-transform: uppercase;
			span {
				background: none repeat scroll 0 0 #707070;
				-moz-box-shadow: -1.4em 0 0 #707070, 1.4em 0 0 #707070;
				-webkit-box-shadow: -1.4em 0 0 #707070, 1.4em 0 0 #707070;
				box-shadow: -1.4em 0 0 #707070, 1.4em 0 0 #707070;
				padding: 0.6em 0;
			}
		}
	}
	&.no-margin {
		margin-top: -10px;
	}
}
.megamenu-banner {
	margin-top: 30px !important;
	h3 {
		font-size: 30px;
		font-family: 'BebasNeueRegular';
	}
	h2 {
		font-size: 40px;
		font-family: 'BebasNeueBold';
	}
}
.wide-banner-4 .cnt-strip .strip {
	h1 {
		font-size: 50px;
		font-family: 'FjallaOneRegular';
	}
	h2 {
		font-size: 40px;
		font-family: 'FjallaOneRegular';
	}
	h3 {
		font-size: 30px;
		font-family: 'FjallaOneRegular';
	}
	h4 {
		font-size: 20px;
		font-family: 'FjallaOneRegular';
	}
	p {
		font-size: 23px;
		font-family: 'Roboto';
		margin-bottom: 0px;
	}
}
.blog-slider-container .blog-slider .blog-post-info {
	.name {
		font-size: 16px;
		font-family: 'FjallaOneRegular';
		a {
			color: #555;
		}
	}
	.info {
		color: #9c9c9c;
		font-size: 12px;
	}
	.text {
		color: #434343;
		font-size: 13px;
	}
}
.footer {
	.links-social {
		border-top: 1px solid #e5e5e5;
		font-size: 12px;
		line-height: 18px;
		color: #666666;
		margin-bottom: 70px;
		.contact-info {
			.footer-logo {
				margin-top: 10px;
			}
			.about-us {
				margin-bottom: 20px;
			}
			.social-icons a {
				color: #888888;
				font-size: 16px;
				-webkit-transition: all 0.2s linear 0s;
				-moz-transition: all 0.2s linear 0s;
				-o-transition: all 0.2s linear 0s;
				transition: all 0.2s linear 0s;
				width: 2em;
				height: 2em;
				display: inline-block;
				-webkit-border-radius: 20px;
				-moz-border-radius: 20px;
				border-radius: 20px;
				text-align: center;
				&:hover, &:focus, &.active {
					-webkit-border-radius: 20px;
					-moz-border-radius: 20px;
					border-radius: 20px;
					color: #fff;
				}
				.icon {
					line-height: 2em;
				}
			}
		}
	}
	.module-heading {
		font-size: 16px;
		text-transform: uppercase;
		font-family: 'FjallaOneRegular';
		color: #555555;
	}
	.contact-timing {
		.table tbody tr {
			border-bottom: 1px solid #e1e1e1;
			td {
				border-top: none;
				font-size: 12px;
				line-height: 36px;
				padding: 0px !important;
			}
		}
		.contact-number {
			font-family: 'FjallaOneRegular';
		}
	}
	.contact-information .media .media-body a {
		color: #666666;
	}
	.footer-bottom {
		background: #202020;
		padding-top: 50px;
		.module-heading {
			font-size: 16px;
			text-transform: uppercase;
			font-family: 'FjallaOneRegular';
			color: #898989;
		}
		.module-body ul li {
			border-bottom: 1px solid #292929;
			a {
				font-size: 12px;
				line-height: 36px;
				color: #666666;
				position: relative;
				margin-left: 23px;
				display: block;
				&:before {
					content: "\f111";
					font-family: FontAwesome;
					position: absolute;
					margin-left: -22px;
					font-size: 6px;
				}
			}
		}
	}
}
.product-tag .item {
	background-color: #f5f5f5;
	color: #666666;
	display: inline-block;
	margin-bottom: 9px;
	margin-right: 8px;
	padding: 6px 12px;
	&.active, &:hover, &:focus {
		color: #fff;
	}
}
.newsletter .sidebar-widget-body input {
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	border: 1px solid #e1e1e1;
	background: #fafafa;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.hot-deals {
	.hot-deal-wrapper {
		position: relative;
		.sale-offer-tag {
			-webkit-border-radius: 50px;
			-moz-border-radius: 50px;
			border-radius: 50px;
			color: #FFFFFF;
			font-size: 13px;
			font-weight: 700;
			height: 50px;
			text-transform: uppercase;
			top: 4%;
			right: 10px;
			width: 50px;
			z-index: 100;
			background: #ff7878;
			position: absolute;
			text-align: center;
			span {
				position: relative;
				z-index: 100;
				top: 4px;
			}
		}
		.timing-wrapper {
			bottom: 20px;
			position: absolute;
			left: 21px;
			.box-wrapper {
				display: table;
				text-align: center;
				margin-right: 10px;
				float: left;
				.box {
					background-color: #a5a5a5;
					-webkit-border-radius: 4px;
					-moz-border-radius: 4px;
					border-radius: 4px;
					color: #FFFFFF;
					display: table-cell;
					height: 44px;
					vertical-align: middle;
					width: 47px;
					line-height: 15px;
					.key {
						display: block;
						font-size: 17px;
						text-transform: uppercase;
						font-weight: 700;
					}
					.value {
						display: block;
						font-size: 11px;
						font-weight: 500;
					}
				}
				&:last-child {
					margin-right: 0px;
				}
			}
		}
	}
	.product-info {
		.name {
			font-size: 18px;
			font-family: 'FjallaOneRegular';
			a {
				color: #555;
			}
		}
		.product-price {
			.price {
				font-weight: 700;
				font-size: 16px;
				line-height: 30px;
				margin-right: 8px;
			}
			.price-before-discount {
				text-decoration: line-through;
				color: #d3d3d3;
				font-weight: 400;
				line-height: 30px;
				font-size: 14px;
			}
		}
	}
	.cart {
		margin-top: 5px;
		-webkit-transition: all 0.2s linear 0s;
		-moz-transition: all 0.2s linear 0s;
		-o-transition: all 0.2s linear 0s;
		transition: all 0.2s linear 0s;
		z-index: 666;
		left: 0px;
		.action {
			float: left;
			.add-cart-button a {
				background: none repeat scroll 0 0 #a8a8a8;
				border: medium none;
				color: #FFFFFF;
				display: block;
				overflow: hidden;
				position: relative;
				text-decoration: none;
				padding: 0px;
				.icon {
					background: none repeat scroll 0 0 #575757;
					color: #FFFFFF;
					height: 100%;
					left: 0;
					font-size: 13px;
					padding: 11px 0 0;
					position: absolute;
					text-align: center;
					top: 0;
					width: 35px;
				}
				span {
					display: block;
					margin-left: 35px;
					overflow: hidden;
					padding: 8px 13px;
				}
				&:hover, &:focus {
					background: #c6c6c6;
				}
			}
		}
	}
}
.breadcrumb {
	background: rgba(0, 0, 0, 0);
	padding: 0;
	ul {
		border-bottom: 1px solid #F2F2F2;
		margin: auto;
		padding: 20px 0 10px;
		text-align: right;
		li {
			&:after {
				color: #666666;
				content: "/";
				font-family: 'Roboto', sans-serif;
				font-size: 12px;
				line-height: 24px;
				margin-left: 9px;
				margin-right: -4px;
				padding: 0px;
			}
			&:last-child:after {
				content: "";
			}
			a {
				color: #666666;
				font-family: 'Roboto', sans-serif;
				font-size: 12px;
				line-height: 24px;
				font-weight: 500;
			}
		}
	}
}
.category-carousel {
	text-align: center;
	cursor: default;
	.item {
		background-color: #FFFFFF;
		background-position: center 55%;
		background-size: cover;
		height: 255px;
		position: relative;
		.caption {
			color: #636363;
			left: 12%;
			letter-spacing: -3px;
			position: absolute;
			top: 10%;
			z-index: 100;
			display: table-cell;
			.big-text {
				font-size: 150px;
				line-height: 145px;
				text-transform: uppercase;
				font-family: 'FjallaOneRegular';
				color: #fe5252;
			}
			.excerpt {
				font-size: 40px;
				letter-spacing: -1px;
				text-transform: uppercase;
				color: #666666;
			}
		}
		.owl-controls {
			display: inline-block;
			position: relative;
			margin-top: 40px;
			.owl-prev, .owl-next {
				display: inline-block;
				position: absolute;
				top: 0;
				bottom: 0;
				width: 30px;
				height: 30px;
				font-size: 21px;
				color: #FFF;
				background-color: #d3d3d3;
				border: none;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				margin: auto;
				-webkit-transition: all 200ms ease-out;
				-moz-transition: all 200ms ease-out;
				-o-transition: all 200ms ease-out;
				transition: all 200ms ease-out;
				opacity: 0;
			}
			.owl-prev {
				left: -35px;
			}
			.owl-next {
				right: -35px;
			}
		}
		&:hover {
			.owl-prev {
				left: -40px;
				opacity: .25;
			}
			.owl-next {
				right: -40px;
				opacity: .25;
			}
			.owl-prev:hover, .owl-next:hover {
				opacity: 1;
			}
		}
	}
}
.filters-container {
	padding: 12px 0px;
	margin-bottom: 5px;
	.nav-tabs.nav-tab-box {
		border: medium none;
		margin-top: 3px;
		li {
			margin-right: 5px;
			padding: 0;
			a {
				background: none repeat scroll 0 0 #FFFFFF;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				border-radius: 0;
				color: #666666;
				font-family: 'Roboto', sans-serif;
				line-height: 18px;
				border: none !important;
				padding: 0px;
				.icon {
					margin-right: 5px;
					color: #aaa;
				}
			}
		}
	}
	.lbl-cnt {
		color: #666666;
		font-family: 'Roboto', sans-serif;
		font-size: 12px;
		line-height: 24px;
		.lbl {
			color: #666666;
			display: inline-block;
			font-family: 'Roboto', sans-serif;
			font-size: 12px;
			line-height: 24px;
			margin-right: 10px;
		}
		.dropdown.dropdown-med {
			.btn {
				border: 1px solid #D6D6D6;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				border-radius: 0;
				padding: 4px 12px;
				background: #fff;
				font-size: 13px;
				.caret {
					margin-left: 13px;
					margin-top: -2px;
				}
			}
			.dropdown-menu {
				-webkit-border-radius: 0px;
				-moz-border-radius: 0;
				border-radius: 0;
				-moz-box-shadow: none;
				-webkit-box-shadow: none;
				box-shadow: none;
				border: 1px solid #e1e1e1;
				li a {
					&:hover, &:focus {
						background: rgba(0, 0, 0, 0);
					}
				}
			}
		}
	}
	.pagination-container {
		margin-top: 4px;
		ul {
			margin: 0px;
			li {
				&.prev, &.next {
					background: none repeat scroll 0 0 #dddddd;
					-webkit-border-radius: 3px;
					-moz-border-radius: 3px;
					border-radius: 3px;
				}
				&.prev a, &.next a, &.prev a:hover, &.next a:hover, &.prev a:focus, &.next a:focus {
					color: #fff;
				}
				a {
					color: #666666;
					display: inline-block;
					font-family: 'Roboto', sans-serif;
					font-size: 13px;
					line-height: 20px;
					padding: 0 3px;
				}
				&.active a {
					text-decoration: underline;
				}
			}
		}
	}
}
.search-result-container {
	.category-product {
		border-top: 1px solid #ececec;
		border-bottom: 1px solid #ececec;
		.product {
			margin-bottom: 30px;
			.product-image .tag {
				width: 60px;
				height: 60px;
				line-height: 55px;
				font-size: 17px;
				right: 14px;
			}
			.cart {
				opacity: 1;
				.lnk {
					margin: 8px 0px !important;
					a {
						background: #a8a8a8;
						padding: 8px 11px !important;
						margin-left: 10px;
						color: #fff !important;
						-webkit-border-radius: 4px;
						-moz-border-radius: 4px;
						border-radius: 4px;
						&:hover, &:focus {
							color: #fff !important;
						}
					}
				}
			}
		}
	}
	.category-product-inner .product-list.product {
		border: 1px solid #f2f2f2;
		position: relative;
		margin-bottom: 30px;
		.product-info {
			padding: 24px 40px;
			.cart {
				margin-top: 30px;
			}
		}
		.tag {
			position: absolute;
			right: 12px;
			top: 18px;
			width: 60px;
			height: 60px;
			line-height: 60px;
			font-size: 17px;
			z-index: 999;
		}
	}
}
.product-list.product .product-info {
	.name {
		font-size: 20px;
	}
	.description {
		line-height: 20px;
	}
}
.single-product {
	.gallery-holder {
		#owl-single-product .single-product-gallery-item {
			border: 1px solid #e5e5e5;
		}
		.gallery-thumbs {
			margin: 15px 0 0;
			position: relative;
			text-align: left;
			.owl-item .item {
				margin-right: 10px;
				border: 1px solid #e5e5e5;
			}
		}
	}
	.product-info {
		.name {
			font-size: 20px;
			line-height: 18px;
			font-family: 'FjallaOneRegular';
			color: #555;
			margin-top: 5px;
		}
		.rating-reviews .reviews .lnk {
			color: #aaaaaa;
		}
		.stock-container .stock-box {
			.label {
				font-size: 16px;
				font-family: 'FjallaOneRegular';
				line-height: 18px;
				text-transform: uppercase;
				color: #666666;
				padding: 0px;
				font-weight: normal;
			}
			.value {
				font-size: 14px;
				color: #ff7878;
			}
		}
		.description-container {
			line-height: 20px;
			color: #666666;
		}
		.price-container {
			border-bottom: 1px solid #F2F2F2;
			border-top: 1px solid #F2F2F2;
			margin-bottom: 0;
			padding: 20px 0;
			.price-box {
				.price {
					font-size: 36px;
					font-weight: 700;
					line-height: 50px;
				}
				.price-strike {
					color: #aaa;
					font-size: 16px;
					font-weight: 300;
					line-height: 50px;
					text-decoration: line-through;
				}
			}
		}
		.quantity-container {
			border-bottom: 1px solid #F2F2F2;
			margin-bottom: 0;
			padding: 20px 0;
			.label {
				font-size: 16px;
				font-family: 'FjallaOneRegular';
				line-height: 40px;
				text-transform: uppercase;
				color: #666666;
				padding: 0px;
				font-weight: normal;
			}
			.cart-quantity .quant-input {
				display: inline-block;
				height: 35px;
				position: relative;
				width: 70px;
				.arrows {
					position: absolute;
					right: 0;
					top: 0;
					z-index: 2;
					height: 100%;
					.arrow {
						box-sizing: border-box;
						display: block;
						text-align: center;
						width: 40px;
						cursor: pointer;
						.ir .icon {
							position: relative;
							&.fa-sort-asc {
								top: 5px;
							}
							&.fa-sort-desc {
								top: -7px;
							}
						}
					}
				}
				input {
					background: none repeat scroll 0 0 #fff;
					border: 1px solid #f2f2f2;
					box-sizing: border-box;
					font-size: 15px;
					height: 35px;
					left: 0;
					padding: 0 20px 0 18px;
					position: absolute;
					top: 0;
					width: 70px;
					z-index: 1;
				}
			}
		}
		.product-social-link {
			.social-label {
				font-size: 15px;
				font-family: 'FjallaOneRegular';
				line-height: 20px;
				text-transform: uppercase;
			}
			.social-icons {
				display: inline-block;
				ul li a {
					color: #888888;
					font-size: 16px;
					-webkit-transition: all 0.2s linear 0s;
					-moz-transition: all 0.2s linear 0s;
					-o-transition: all 0.2s linear 0s;
					transition: all 0.2s linear 0s;
					padding: 5px 6px;
					&:hover, &:focus {
						-webkit-border-radius: 50px;
						-moz-border-radius: 50px;
						border-radius: 50px;
						color: #fff;
					}
				}
			}
		}
	}
	.product-tabs {
		margin-top: 60px;
		.nav.nav-tabs.nav-tab-cell > li {
			float: none !important;
			border-bottom: 1px solid #f2f2f2;
			> a {
				border: none;
				color: #555;
				display: block;
				padding: 12px 28px;
				font-size: 18px;
				font-family: 'FjallaOneRegular';
				line-height: 28px;
				text-transform: uppercase;
				position: relative;
				&:hover, &:focus {
					-webkit-border-radius: 0px;
					-moz-border-radius: 0px;
					border-radius: 0px;
					color: #fff;
				}
				&:hover:before, &:focus:before {
					border-color: rgba(0, 0, 0, 0) #e0e0e0 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
					right: -10px;
				}
				&:hover:after, &:focus:after {
					border-style: solid;
					border-width: 7.5px 1px 7.5px 10px;
					content: "";
					height: 0;
					position: absolute;
					top: 20px;
					width: 0;
					right: -8px;
				}
			}
			&.active > a {
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				color: #fff;
				&:before {
					border-color: rgba(0, 0, 0, 0) #e0e0e0 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
					right: -10px;
				}
				&:after {
					border-style: solid;
					border-width: 7.5px 1px 7.5px 10px;
					content: "";
					height: 0;
					position: absolute;
					top: 20px;
					width: 0;
					right: -8px;
				}
			}
		}
		.tab-content {
			border: 1px solid #f2f2f2;
			.tab-pane {
				padding: 24px;
				.text {
					line-height: 20px;
				}
			}
		}
	}
	.upsell-product .product .product-info .name {
		margin-top: 20px;
		font-size: 16px;
	}
	#owl-single-product-thumbnails .owl-controls {
		position: absolute;
		text-align: center;
		top: auto;
		width: 100%;
		margin-top: 10px;
		.owl-pagination .owl-page {
			display: inline-block;
			span {
				background: none repeat scroll 0 0 #ddd;
				border: medium none;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;
				display: block;
				height: 12px;
				margin: 0 5px;
				-webkit-transition: all 200ms ease-out 0s;
				-moz-transition: all 200ms ease-out 0s;
				-o-transition: all 200ms ease-out 0s;
				transition: all 200ms ease-out 0s;
				width: 12px;
				cursor: pointer;
			}
		}
	}
	.sidebar .sidebar-module-container .sidebar-widget .section-title {
		margin-top: 0px;
	}
}
.cnt-homepage .single-product {
	.single-product-gallery {
		.gallery-thumbs .nav-holder {
			background-color: #FFFFFF;
			height: 100%;
			position: absolute;
			top: 0;
			width: 30px;
			z-index: 50;
			&.left {
				left: 0px;
			}
			&.right {
				right: 0;
			}
			.prev-btn {
				left: 0;
				&:after {
					content: "\f104";
				}
			}
			.next-btn {
				right: 0px;
				&:after {
					content: "\f105";
				}
			}
			.prev-btn, .next-btn {
				background-color: #fff;
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				display: inline-block;
				height: 100%;
				position: absolute;
				vertical-align: top;
				width: 90%;
				z-index: 100;
				border: 1px solid #e5e5e5;
				color: #dadada;
			}
			.prev-btn:after, .next-btn:after {
				bottom: 0;
				font-family: fontawesome;
				font-size: 30px;
				height: 30px;
				left: 0;
				line-height: 30px;
				margin: auto;
				position: absolute;
				right: 0;
				text-align: center;
				top: 0;
			}
			.prev-btn:hover, .next-btn:hover, .prev-btn:focus, .next-btn:focus {
				background: #dadada;
				color: #fff;
			}
		}
		.owl-item {
			.single-product-gallery-item > a > img {
				display: block;
				width: 100%;
			}
			.single-product-gallery-thumbs.gallery-thumbs .owl-item {
				margin-left: 10px;
			}
		}
	}
	.product-info-block {
		label {
			font-size: 13px;
			font-weight: normal;
			line-height: 30px;
			color: #434343 !important;
		}
		.label {
			font-size: 13px;
			font-weight: normal;
			line-height: 30px;
			color: #434343 !important;
			padding: 0px;
		}
		.cart {
			width: auto;
			left: 0;
			margin-top: -8px;
			padding: 0px;
			.action .left {
				padding: 2px 8px;
				margin-left: 5px;
			}
		}
		.form-control {
			.selectpicker {
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				-moz-box-shadow: none;
				-webkit-box-shadow: none;
				box-shadow: none;
				border: 1px solid #f1f1f1;
				background: #fff;
				color: #b0b0b0;
			}
			.dropdown-menu {
				-moz-box-shadow: none;
				-webkit-box-shadow: none;
				box-shadow: none;
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				border: 1px solid #f1f1f1;
				ul li a {
					&:hover, &:focus {
						background: rgba(0, 0, 0, 0);
					}
				}
			}
		}
		.txt.txt-qty {
			font-size: 15px;
			line-height: 18px;
			border: 1px solid #f1f1f1;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border-radius: 3px;
			height: 30px;
			padding: 5px 10px;
			text-align: center;
			width: 60px;
		}
		.stock-container .stock-box {
			.label {
				color: #434343;
				font-family: 'Roboto';
				font-size: 13px;
				font-weight: normal;
				line-height: 20px;
				padding: 0;
				text-transform: none;
			}
			.value {
				font-size: 13px;
			}
		}
	}
	.product-tabs {
		.nav-tab-cell-detail li {
			margin-right: 10px;
			padding: 0;
			a {
				border: 2px solid #e1e1e1;
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				color: #666666;
				font-family: 'FjallaOneRegular';
				font-size: 20px;
				line-height: 30px;
				padding-bottom: 4px;
				padding-top: 4px;
				text-transform: uppercase;
				&:hover, &:focus {
					color: #fff;
				}
			}
			&.active a {
				color: #fff;
			}
		}
		.tab-content {
			border: none;
			.tab-pane {
				padding: 0px;
				.product-tab .text {
					font-size: 13px;
					line-height: 22px;
				}
			}
		}
	}
}
.single-product .second-gallery-thumb.gallery-thumbs {
	padding: 0 40px;
	#owl-single-product2-thumbnails .owl-wrapper-outer {
		margin-left: 5px;
	}
}
.product-tabs .tab-content .tab-pane {
	.product-reviews {
		.title {
			color: #666666;
			font-size: 16px;
			font-weight: 500;
			line-height: 20px;
			margin: 0 0 10px;
			text-transform: uppercase;
			font-family: 'FjallaOneRegular';
		}
		.reviews .review {
			margin-bottom: 20px;
			font-family: 'Roboto', sans-serif;
			text-transform: none;
			.review-title {
				margin-bottom: 5px;
				.summary {
					color: #666666;
					font-size: 14px;
					font-weight: 300;
					line-height: 45px;
					margin-right: 10px;
					text-transform: uppercase;
				}
				.date {
					font-size: 12px;
					span {
						margin-left: 5px;
					}
				}
			}
			.text {
				line-height: 18px;
			}
			.author span {
				margin-left: 5px;
			}
		}
	}
	.product-add-review {
		.title {
			color: #666666;
			font-size: 16px;
			font-weight: 500;
			line-height: 20px;
			margin: 0 0 20px;
			text-transform: uppercase;
			font-family: 'FjallaOneRegular';
		}
		.review-table .table {
			thead th {
				font-weight: normal;
				border-bottom-width: 1px;
				text-align: center;
				vertical-align: middle;
			}
			tbody tr td {
				text-align: center;
				vertical-align: middle;
				input {
					float: none;
					margin: auto;
				}
			}
		}
		.review-form {
			label {
				font-weight: normal;
				font-size: 13px;
				.astk {
					color: #FF0000;
					font-size: 12px;
				}
			}
			.txt {
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				-moz-box-shadow: none;
				-webkit-box-shadow: none;
				box-shadow: none;
			}
		}
	}
	.product-tag {
		.title {
			color: #666666;
			font-size: 16px;
			font-weight: 500;
			line-height: 20px;
			margin: 0 0 20px;
			text-transform: uppercase;
			font-family: 'FjallaOneRegular';
		}
		.form-group {
			label {
				font-weight: 300;
				font-size: 14px;
				line-height: 24px;
				margin-right: 10px;
			}
			.txt {
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				-moz-box-shadow: none;
				-webkit-box-shadow: none;
				box-shadow: none;
			}
			.text {
				margin-left: 90px;
			}
		}
	}
}
.furniture-container {
	.product {
		position: relative;
		.btn-primary {
			font-size: 15px;
			line-height: 30px;
			font-family: 'FjallaOneRegular';
			background: #cbc9c9;
			text-transform: uppercase;
			border: none;
			color: #fff;
			&:hover, &:focus {
				border: none;
			}
		}
	}
	.best-seller .product .product-info .name, .special-offer .product .product-info .name {
		margin-top: 4px;
	}
	.cart {
		margin-top: 5px;
		opacity: 1;
		-webkit-transition: all 0.2s linear 0s;
		-moz-transition: all 0.2s linear 0s;
		-ms-transition: all 0.2s linear 0s;
		-o-transition: all 0.2s linear 0s;
		transition: all 0.2s linear 0s;
		width: 100%;
		z-index: 666;
		left: 0px;
		.action {
			float: left;
			.add-cart-button .btn.btn-primary.icon {
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
				background: #575757;
			}
			&.lnk {
				margin: 10px 0px;
				a {
					padding: 0 10px;
					color: #dadada;
				}
				&.wishlist {
					border-right: 1px solid #dadada;
				}
			}
		}
	}
}
.homepage-container {
	.product {
		position: relative;
		.product-image .tag {
			font-size: 15px;
			font-weight: 700;
			width: 50px;
			height: 50px;
			text-transform: uppercase;
			top: 2.5%;
			z-index: 100;
			-webkit-border-radius: 50px;
			-moz-border-radius: 50px;
			border-radius: 50px;
			color: #fff;
			right: 25px;
			/*11px;*/
			text-align: center;
			position: absolute;
			span {
				position: relative;
				z-index: 100;
				line-height: 48px;
			}
			&.new {
				background: #46aad7;
			}
			&.hot {
				background: #ff7878;
			}
		}
		.product-info {
			.name {
				font-size: 18px !important;
				font-family: 'FjallaOneRegular';
				a {
					color: #555;
				}
			}
			.star-rating .color {
				color: #ffb400;
			}
			.product-price {
				.price {
					font-weight: 700;
					font-size: 16px;
					line-height: 30px;
					margin-right: 8px;
				}
				.price-before-discount {
					text-decoration: line-through;
					color: #d3d3d3;
					font-weight: 400;
					line-height: 30px;
					font-size: 14px;
				}
			}
		}
		.cart {
			margin-top: 5px;
			-webkit-transition: all 0.2s linear 0s;
			-moz-transition: all 0.2s linear 0s;
			-o-transition: all 0.2s linear 0s;
			transition: all 0.2s linear 0s;
			width: 100%;
			z-index: 666;
			left: 0px;
			opacity: 1;
			.action {
				float: left;
				.add-cart-button .btn.btn-primary.icon {
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;
					background: #575757;
				}
				&.lnk {
					margin: 10px 0px;
					a {
						padding: 0 10px;
						color: #dadada;
					}
					&.wishlist {
						border-right: 1px solid #dadada;
					}
				}
			}
		}
	}
	.featured-product .products .product:first-child {
		margin-bottom: 30px;
	}
	.blog-slider-container .blog-slider .blog-post-info .name {
		font-size: 18px;
	}
	.btn-primary {
		font-size: 15px;
		line-height: 30px;
		font-family: 'FjallaOneRegular';
		background: rgba(0, 0, 0, 0);
		text-transform: uppercase;
		border: 2px solid #f2f2f2;
		color: #747474;
		&:hover, &:focus {
			color: #fff;
		}
	}
	.best-seller .product .product-info .name, .special-offer .product .product-info .name {
		margin-top: 4px;
	}
	.cart {
		margin-top: 5px;
		opacity: 1;
		-webkit-transition: all 0.2s linear 0s;
		-moz-transition: all 0.2s linear 0s;
		-o-transition: all 0.2s linear 0s;
		transition: all 0.2s linear 0s;
		width: 100%;
		z-index: 666;
		left: 0px;
		.action .add-cart-button .btn.btn-primary.icon {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			background: #575757;
		}
	}
}
.wrapper .box {
	margin-left: auto;
	margin-right: auto;
	padding-left: 50px;
	padding-right: 50px;
	.wrapper-inner {
		background: none repeat scroll 0 0 #FFFFFF;
		-moz-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
		-webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
		.wrapper-body-inner .owl-item .tag {
			font-size: 15px;
			font-weight: 700;
			line-height: 55px;
			width: 55px;
			height: 55px;
			text-transform: uppercase;
			top: 2.5%;
			z-index: 100;
			-webkit-border-radius: 50px;
			-moz-border-radius: 50px;
			border-radius: 50px;
			color: #fff;
			right: 25px;
			/*11px;*/
			text-align: center;
			span {
				position: relative;
				z-index: 100;
			}
			&.new {
				background: #46aad7;
			}
			&.sale {
				background: #989898;
			}
			&.hot {
				background: #ff7878;
			}
		}
	}
}
/*===================================================================================*/
/*  Responsive Layout
/*===================================================================================*/
/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
	.top-bar .header-top-inner {
		text-align: center;
		.cnt-account {
			clear: both;
			display: inline-block;
			float: none;
			margin: auto;
			text-align: center;
			li {
				margin-bottom: 8px;
			}
		}
		.cnt-block .list-inline li {
			margin: 7px 0;
		}
	}
	.main-header {
		.logo-holder {
			text-align: center;
			margin-bottom: 20px;
		}
		.top-search-holder {
			.contact-row {
				margin-bottom: 20px;
				text-align: center;
				.phone, .contact {
					margin: 0 0 5px;
					padding-right: 0px;
					border-right: none;
					display: block;
				}
			}
			.search-area {
				.categories-filter {
					width: 100%;
					border-right: none;
					border-bottom: 1px solid #e0e0e0;
				}
				.search-button {
					padding: 12px 19px;
				}
			}
		}
	}
	.sidebar .side-menu nav .nav > li > .mega-menu {
		left: 0px;
		min-width: 100%;
		top: 100%;
	}
	.logo-slider-inner .item {
		text-align: center;
	}
	.filters-container {
		.filter-tabs, .lbl-cnt, .pagination-container {
			margin-bottom: 10px;
		}
	}
	.homepage-container .wide-banners .wide-banner {
		margin-bottom: 10px;
	}
	.cnt-homepage .wrapper .box {
		padding: 0 10px;
	}
	#owl-main .item .caption {
		padding: 0 22px;
	}
	.wide-banners .wide-banner:first-child {
		margin-bottom: 10px;
	}
	.product .product-image .image img {
		width: 100%;
		height: auto;
		display: block;
	}
	.header-nav .yamm .dropdown-menu {
		background: #fff;
	}
	.body-content .sidebar {
		margin-bottom: 30px;
	}
	.filters-container .no-padding {
		padding: 0px;
	}
	.search-result-container .category-product-inner .product-list.product .product-info {
		padding: 0px;
		.cart {
			margin-bottom: 20px;
		}
	}
	.single-product {
		.gallery-holder {
			margin-bottom: 30px;
		}
		.product-info-block {
			clear: both;
		}
	}
	.yamm .dropdown-menu.pages {
		padding: 10px 37px;
	}
	.hot-deal-wrapper .image img {
		width: 100%;
		height: auto;
		display: block;
	}
	.seller-product .products .product .product-info .name {
		font-size: 15px;
		margin-top: 0px !important;
	}
	.cnt-homepage {
		.single-product .product-tabs .nav-tab-cell-detail li a {
			font-size: 16px;
		}
		.header-nav .yamm .navbar-header {
			border: 1px solid #f1f1f1;
		}
	}
	.info-boxes .info-box {
		margin-bottom: 10px;
	}
	.cnt-homepage .single-product .product-info-block .cart {
		margin-top: 10px;
		padding: 0 15px;
	}
	.product-comparison .compare-table tr td .product .product-image .image img {
		width: auto;
	}
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
	.top-bar .cnt-account ul > li {
		padding: 3px 2px;
	}
	.sidebar .side-menu nav .nav > li > .mega-menu {
		min-width: 100%;
		left: 0px;
	}
	.logo-slider-inner .item {
		text-align: center;
	}
	.filters-container {
		.filter-tabs, .lbl-cnt {
			margin-bottom: 10px;
		}
		.pagination-container {
			margin-bottom: 10px;
			float: left;
		}
	}
	.homepage-container .wide-banners .wide-banner {
		margin-bottom: 10px;
	}
	.cnt-homepage {
		.wrapper .box {
			padding: 0 15px;
		}
		.container {
			width: 100%;
		}
	}
	.body-content .sidebar {
		margin-bottom: 30px;
	}
	.product .product-image .image img, .blog-slider .image img {
		width: 100%;
		height: auto;
		display: block;
	}
	.owl-item {
		padding: 10px;
	}
	#owl-main .owl-item {
		padding: 0px;
	}
	.wide-banners .wide-banner:first-child {
		margin-bottom: 10px;
	}
	.filters-container .no-padding, .search-result-container .category-product-inner .product-list.product .product-info {
		padding: 0px;
	}
	.hot-deal-wrapper .image img {
		width: 100%;
		height: auto;
		display: block;
	}
	.main-header {
		.logo-holder {
			text-align: center;
			margin-bottom: 20px;
		}
		.contact-row {
			text-align: center;
		}
	}
}
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
.blog-page {
	.blog-post {
		h1 {
			font-family: 'FjallaOneRegular';
			font-size: 25px;
			text-transform: uppercase;
			a {
				color: #555;
			}
		}
		span {
			padding-right: 20px;
			color: #aaa;
			font-size: 13px;
		}
		p {
			padding-top: 16px;
			text-align: justify;
			font-size: 13px;
			color: #666666;
			margin-bottom: 0px;
		}
		a {
			margin-top: 10px;
		}
		.blog-pagination .pagination {
			-webkit-border-radius: 0px;
			-moz-border-radius: 0px;
			border-radius: 0px;
		}
		.social-media {
			margin-top: 20px;
			margin-bottom: 30px;
			span {
				font-size: 13px;
				text-transform: uppercase;
				color: #434343;
			}
			a {
				font-size: 18px;
				margin: 0px 10px 0px 0px;
				height: 30px;
				width: 30px;
				display: inline-block;
				text-align: center;
				-webkit-border-radius: 20px;
				-moz-border-radius: 20px;
				border-radius: 20px;
				color: #888888;
			}
		}
	}
	.sidebar .sidebar-module-container {
		.sidebar-widget {
			.blog-post {
				h4 {
					font-family: 'FjallaOneRegular';
					font-size: 14px;
					text-transform: uppercase;
					a {
						color: #555;
					}
				}
				span {
					padding-right: 10px;
					color: #aaa;
					font-size: 12px;
				}
				p {
					padding-top: 10px;
					text-align: justify;
					font-size: 12px;
					color: #666666;
				}
			}
			.nav-tabs {
				border-bottom: none;
				> li {
					text-transform: uppercase;
					color: #666666;
					> a {
						-webkit-border-radius: 0px;
						-moz-border-radius: 0px;
						border-radius: 0px;
						font-size: 13px;
						border: none;
					}
					&:first-child > a {
						padding-right: 10px;
						border-right: 1px solid #d4d4d4;
					}
					&:last-child > a {
						padding-left: 10px;
					}
				}
			}
			.nav > li > a {
				padding: 0px;
				color: #666666;
			}
		}
		.search-area {
			.search-button:after {
				content: "\f002";
				font-family: fontawesome;
				font-size: 13px;
				position: absolute;
				top: 14px;
				right: 30px;
				background-color: #fff;
				padding-left: 10px;
			}
			input {
				font-size: 12px;
				color: #9e9e9e;
				padding: 14px;
				border: 1px solid #e1e1e1;
				width: 100%;
				position: relative;
			}
		}
	}
	.blog-pagination {
		border-top: 1px solid #ececec;
		.pagination > li {
			&:first-child > a {
				margin-right: 10px;
			}
			&:last-child > a {
				margin-left: 2px;
			}
		}
	}
	.side-bar-blog .widget .categories .side-bar-title h3 {
		font-family: 'FjallaOneRegular';
		font-size: 18px;
		text-transform: uppercase;
		color: #555;
		padding-bottom: 8px;
		border-bottom: 1px solid #e3e3e3;
		margin: 0px;
	}
	.blog-post-author-details {
		padding: 40px 0px;
		border-top: 1px solid #e3e3e3;
		border-bottom: 1px solid #e3e3e3;
		.author-social-network {
			display: inline-block;
			button {
				background-color: #fff;
				border: 1px solid #e3e3e3;
				.twitter-icon {
					-webkit-border-radius: 20px;
					-moz-border-radius: 20px;
					border-radius: 20px;
					color: #fff;
					font-size: 16px;
					height: 26px;
					width: 26px;
					text-align: center;
					display: inline-block;
					margin: 6px;
					line-height: 26px;
				}
			}
			> span {
				font-size: 13px;
				color: #434343;
				text-transform: uppercase;
				margin-right: 16px;
			}
			.caret {
				color: #e3e3e3;
			}
		}
		.btn-group.open .dropdown-toggle {
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
			box-shadow: none;
		}
		img {
			float: left;
		}
		.author-job {
			display: block;
			padding: 0px;
			color: #aaa;
			font-size: 12px;
		}
		h4 {
			display: inline-block;
			font-size: 15px;
			font-family: 'FjallaOneRegular';
			text-transform: uppercase;
			color: #555;
			margin: 0px;
		}
		p {
			font-size: 13px;
			color: #666666;
			padding: 16px 0px 0px 0px;
			text-align: justify;
		}
	}
	.blog-review {
		.title-review-comments {
			font-size: 16px;
			color: #555;
			font-family: 'FjallaOneRegular';
			margin-bottom: 40px;
			text-transform: uppercase;
		}
		p {
			font-size: 13px;
			color: #666666;
			text-align: justify;
		}
		.review-action {
			font-size: 12px;
			color: #666666;
		}
		.post-load-more {
			text-align: center;
			border-bottom: 1px solid #e3e3e3;
			padding-bottom: 30px;
		}
	}
	.blog-post {
		.author:before, .review:before, .date-time:before {
			color: #666666;
			font-size: 13px;
			padding-right: 4px;
			font-family: FontAwesome;
		}
	}
	.sidebar .sidebar-module-container .sidebar-widget .blog-post {
		.author:before, .review:before, .date-time:before {
			color: #666666;
			font-size: 13px;
			padding-right: 4px;
			ont-family: FontAwesome;
		}
	}
	.blog-post .author:before, .sidebar .sidebar-module-container .sidebar-widget .blog-post .author:before {
		content: "\f044";
	}
	.blog-post .review:before, .sidebar .sidebar-module-container .sidebar-widget .blog-post .review:before {
		content: "\f086";
	}
	.blog-post .date-time:before {
		content: "\f073";
	}
	.sidebar .sidebar-module-container .sidebar-widget {
		.blog-post .date-time:before {
			content: "\f073";
		}
		.nav-tabs > li.active > a {
			background-color: #fff;
			&:hover, &:focus {
				background-color: #fff;
			}
		}
		.nav > li > a {
			&:hover, &:focus {
				background-color: #fff;
			}
		}
	}
	.blog-post .social-media {
		a {
			&:hover, &:focus {
				-webkit-border-radius: 20px;
				-moz-border-radius: 20px;
				border-radius: 20px;
				color: #fff;
			}
		}
		i {
			padding-top: 6px;
		}
	}
	.blog-post-author-details .author-social-network button a i {
		padding-top: 6px;
	}
	.blog-review h4, .blog-write-comment h4 {
		font-size: 16px;
		color: #555;
		font-family: 'FjallaOneRegular';
		text-transform: uppercase;
		display: inline-block;
	}
}
.contact-page .contact-title h4 {
	font-size: 16px;
	color: #555;
	font-family: 'FjallaOneRegular';
	text-transform: uppercase;
	display: inline-block;
}
.blog-page {
	.blog-review {
		.review-action a {
			&:hover, &:focus {
				text-decoration: underline;
			}
		}
		.blog-comments, .blog-sub-comments {
			border-bottom: 1px solid #e3e3e3;
		}
	}
	.blog-post-author-details .author-social-network .dropdown-menu {
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		> li > a {
			.icon {
				margin-right: 5px;
			}
			&:hover, &:focus {
				background: rgba(0, 0, 0, 0);
			}
		}
	}
}
.checkout-box {
	.checkout-steps {
		.checkout-step-01 .already-registered-login {
			form .form-group .info-title {
				font-family: 'Roboto', sans-serif;
				font-weight: normal;
				margin-bottom: 10px;
				font-size: 14px;
			}
			.forgot-password {
				padding-top: 14px;
				display: inline-block;
			}
			a {
				font-size: 14px;
				color: #666666;
				text-decoration: underline;
			}
			button {
				margin-top: 15px;
			}
		}
		.panel .panel-heading .unicase-checkout-title {
			margin: 0px !important;
			a {
				color: #555;
				text-transform: uppercase;
				display: block;
				span {
					background-color: #aaaaaa;
					color: #fff !important;
					display: inline-block;
					margin-right: 10px;
					padding: 15px 20px;
				}
			}
		}
		.checkout-subtitle {
			font-family: 'FjallaOneRegular';
			font-size: 16px;
			text-transform: uppercase;
			color: #434343;
			margin-right: 12px;
		}
		.panel-body {
			padding: 50px;
		}
		.guest-login {
			form .radio-checkout-unicase .guest-check {
				margin-bottom: 6px;
			}
			ul .save-time-reg {
				padding-bottom: 4px;
			}
		}
	}
	.panel-group .panel {
		-webkit-border-radius: 0px !important;
		-moz-border-radius: 0px !important;
		border-radius: 0px !important;
	}
	.checkout-progress-sidebar {
		.panel-body ul li a {
			color: #666666;
		}
		.panel .panel-heading {
			padding: 27px 30px;
			border-bottom: 1px solid #ddd;
			h4 {
				margin: 0px;
			}
		}
	}
	.checkout-steps {
		.checkout-step-01 .guest-login form .radio input[type="radio"] {
			margin-left: 0px;
		}
		form {
			.radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
				margin-left: 0px;
			}
		}
		.panel .panel-heading {
			font-family: 'FjallaOneRegular';
			font-size: 20px;
			-webkit-border-radius: 0px;
			-moz-border-radius: 0px;
			border-radius: 0px;
			text-transform: uppercase;
		}
	}
	.checkout-progress-sidebar .panel .panel-heading {
		font-family: 'FjallaOneRegular';
		font-size: 20px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		text-transform: uppercase;
	}
	.checkout-steps {
		.guest-login .title-tag-line, .already-registered-login .title-tag-line {
			margin-bottom: 15px;
			font-size: 14px;
		}
		.guest-login {
			form .radio-checkout-unicase, ul {
				padding-left: 10px;
				font-size: 14px;
			}
		}
		.checkout-step-01 .already-registered-login a {
			&:hover, &:focus {
				background-color: rgba(0, 0, 0, 0);
			}
		}
	}
	.checkout-progress-sidebar .panel-body ul li a:hover {
		background-color: rgba(0, 0, 0, 0);
	}
}
.contact-page {
	.contact-map iframe {
		height: 400px;
		width: 100%;
	}
	.contact-info {
		font-size: 12px;
		color: #666;
		.contact-i {
			display: inline-block;
			height: 30px;
			width: 30px;
			text-align: center;
			-webkit-border-radius: 50px;
			-moz-border-radius: 50px;
			border-radius: 50px;
			color: #fff;
			margin-right: 16px;
			float: left;
			i {
				padding-top: 8px;
				font-size: 14px;
			}
		}
		.contact-span {
			display: block;
		}
	}
	.contact-title h4 {
		margin-bottom: 30px;
	}
	.contact-info {
		.address, .phone-no {
			margin-bottom: 10px;
		}
	}
}
.shopping-cart {
	.shopping-cart-table {
		margin-top: 20px;
		margin-bottom: 50px;
		table {
			margin-bottom: 0px !important;
			tbody tr {
				td {
					vertical-align: middle;
				}
				.romove-item a {
					font-size: 18px;
					color: #666666;
					&:hover, &:focus {
						color: #ff000 !important;
					}
				}
				.cart-image .entry-thumbnail {
					display: block;
					text-align: center;
				}
				.cart-product-name-info {
					vertical-align: middle !important;
					h4 {
						margin-top: 0px;
						font-family: 'FjallaOneRegular';
						font-size: 16px;
						text-transform: uppercase;
						a {
							color: #555;
						}
					}
					.reviews {
						font-size: 11px;
						color: #aaa;
					}
					.cart-product-info {
						margin-top: 10px;
						span {
							font-family: 'FjallaOneRegular';
							font-size: 12px;
							color: #666666;
							text-transform: uppercase;
							span {
								font-family: 'Roboto', sans-serif;
								font-size: 12px;
								text-transform: lowercase;
								margin-left: 14px;
							}
						}
					}
				}
				.cart-product-edit a {
					color: #666666;
					text-decoration: underline;
				}
			}
		}
		.table > thead > tr > th {
			text-align: center;
			padding: 16px;
			font-family: 'FjallaOneRegular';
			font-size: 15px;
		}
		.shopping-cart-btn span {
			padding: 20px 0px;
			display: block;
		}
	}
	.estimate-ship-tax table {
		thead tr {
			th {
				.estimate-title {
					font-family: 'FjallaOneRegular';
					font-size: 19px;
					color: #555;
					text-transform: uppercase;
					margin-bottom: 2px;
					margin-top: 0px;
					display: block;
				}
				p {
					font-family: 'Roboto', sans-serif;
					font-size: 13px;
					color: #666666;
					font-weight: normal;
					margin-bottom: 0px;
				}
			}
			> th {
				padding: 24px 24px 20px 24px;
			}
		}
		tbody {
			tr > td {
				padding: 24px !important;
			}
			.unicase-form-control {
				.selectpicker {
					background: #fff;
					color: #999;
					-moz-box-shadow: none;
					-webkit-box-shadow: none;
					box-shadow: none;
					border: 1px solid #eee;
				}
				.dropdown-menu.open ul li a {
					&:hover, &:focus {
						background: rgba(0, 0, 0, 0);
					}
				}
			}
		}
	}
	.cart-shopping-total table {
		thead tr th {
			background-color: #fafafa;
			text-align: right;
			padding: 24px 50px;
			text-transform: uppercase;
			.cart-sub-total {
				color: #555;
				margin-bottom: 7px;
			}
		}
		tbody tr td {
			padding: 24px 50px;
			.cart-checkout-btn {
				button {
					float: right !important;
					margin-bottom: 8px;
				}
				span {
					display: block;
					font-weight: normal;
					color: #666666;
				}
			}
		}
	}
	.shopping-cart-table table tbody tr {
		.romove-item, .cart-product-edit, .cart-product-sub-total, .cart-product-grand-total, .cart-product-quantity {
			text-align: center;
		}
		.cart-product-sub-total span, .cart-product-grand-total span {
			display: block;
			font-family: 'FjallaOneRegular';
			font-size: 20px;
			color: #555;
			text-transform: uppercase;
		}
	}
	.estimate-ship-tax table tbody tr td .form-group {
		label, input {
			font-size: 13px;
			font-weight: normal;
			color: #999;
		}
	}
}
.blog-page .blog-write-comment .form-group {
	label, input {
		font-size: 13px;
		font-weight: normal;
		color: #999;
	}
}
.contact-page .contact-form form {
	label, input {
		font-size: 13px;
		font-weight: normal;
		color: #999;
	}
}
.shopping-cart .estimate-ship-tax table tbody tr td .form-group label span, .blog-page .blog-write-comment label span, .contact-page .contact-form form label span, .checkout-box .checkout-steps .checkout-step-01 .already-registered-login form .form-group label span {
	color: red;
}
.shopping-cart .cart-shopping-total table thead tr th {
	.cart-sub-total, .cart-grand-total {
		font-family: 'FjallaOneRegular';
		font-size: 22px;
	}
}
.shopping-cart-table table tbody tr td .quant-input {
	display: inline-block;
	height: 35px;
	position: relative;
	width: 70px;
	.arrows {
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
		.arrow {
			box-sizing: border-box;
			cursor: pointer;
			display: block;
			text-align: center;
			width: 40px;
			.ir .icon {
				&.fa-sort-asc {
					top: 5px;
				}
				&.fa-sort-desc {
					top: -7px;
				}
				position: relative;
			}
		}
	}
	input {
		background: none repeat scroll 0 0 #FFFFFF;
		border: 1px solid #F2F2F2;
		box-sizing: border-box;
		font-size: 15px;
		height: 35px;
		left: 0;
		padding: 0 20px 0 18px;
		position: absolute;
		top: 0;
		width: 70px;
		z-index: 1;
	}
}
.product-comparison {
	.page-title {
		font-family: 'FjallaOneRegular';
		font-size: 30px;
		text-transform: uppercase;
		color: #666666;
		margin-bottom: 40px;
	}
	.compare-table tr {
		th {
			font-size: 20px;
			font-weight: 500;
			color: #666666;
			padding: 18px 25px;
			vertical-align: middle;
			border: 1px solid #cccccc;
		}
		td {
			padding: 18px 30px;
			border: 1px solid #cccccc;
			.product {
				margin-bottom: 15px;
				margin-top: 40px;
				.product-image .image a {
					display: block;
					text-align: center;
				}
			}
			.product-price {
				.price {
					font-size: 16px;
					font-weight: 700;
					line-height: 30px;
					margin-right: 8px;
				}
				.price-before-discount {
					color: #D3D3D3;
					font-size: 14px;
					font-weight: 400;
					line-height: 30px;
					text-decoration: line-through;
				}
			}
			.text {
				font-size: 14px;
				line-height: 22px;
			}
			.in-stock {
				font-size: 15px;
				font-weight: 700;
				margin-bottom: 0px;
			}
			.remove-icon {
				color: #666666;
				&:hover, &:focus {
					color: #ff6666;
				}
			}
		}
	}
}
.body-content {
	.x-page .x-text {
		h1 {
			font-family: 'FjallaOneRegular';
			font-size: 200px;
		}
		p {
			font-size: 25px;
			font-style: normal;
			font-weight: 300;
		}
		.le-button {
			border-radius: 0 3px 3px 0;
			margin: 0 0 0 -5px;
			padding: 21px 23px 20px;
			font-size: 15px;
			font-weight: bold;
			line-height: 10px;
			border: medium none;
			color: #fff;
		}
		form input {
			border: 1px solid #e0e0e0;
			border-radius: 3px 0 0 3px;
			color: #3d3d3d;
			padding: 13px;
			font-size: 15px;
			width: 40%;
		}
		a {
			font-size: 15px;
			i {
				padding-right: 2px;
			}
		}
	}
	.terms-conditions-page .terms-conditions h2, .faq-page h2, .track-order-page h2 {
		font-size: 30px;
		text-transform: uppercase;
		color: #555;
		text-align: center;
		font-family: 'FjallaOneRegular';
	}
	.terms-conditions-page .terms-conditions {
		h3 {
			font-size: 24px;
			text-transform: uppercase;
			color: #555;
			font-family: 'FjallaOneRegular';
			margin-bottom: 30px;
		}
		ol {
			padding-left: 22px;
			li {
				font-weight: 300;
				font-style: normal;
				font-size: 15px;
				color: #666;
				padding-bottom: 20px;
			}
		}
		p {
			font-size: 15px;
		}
		h2, span {
			text-align: center;
		}
	}
	.track-order-page span {
		text-align: center;
	}
	.terms-conditions-page .terms-conditions span, .faq-page .title-tag, .track-order-page span {
		display: block;
		font-weight: 300;
		font-style: normal;
		font-size: 15px;
		color: #666;
	}
	.faq-page .title-tag {
		text-align: center;
		padding-bottom: 80px;
	}
	.track-order-page .register-form {
		label {
			font-weight: 400;
			font-size: 14px;
		}
		.form-group {
			margin-bottom: 25px;
		}
	}
	.my-wishlist-page .my-wishlist table {
		> thead > tr > th {
			text-align: center;
			font-size: 30px;
			font-family: 'FjallaOneRegular';
			text-transform: uppercase;
			border: none;
			font-weight: 400;
		}
		tbody {
			tr:nth-child(even) {
				border-top: 1px solid #ddd;
			}
			.product-name {
				font-size: 20px;
				font-family: 'FjallaOneRegular';
				padding-bottom: 6px;
				a {
					color: #434343;
				}
			}
			.rating {
				span {
					font-size: 11px;
					color: #aaa;
					padding-left: 10px;
				}
				.rate {
					color: #ffb400;
				}
				.non-rate {
					color: #dcdcdc;
				}
			}
			.price {
				font-size: 20px;
				padding-top: 4px;
				font-weight: bold;
				span {
					font-size: 15px;
					color: #ddd;
					text-decoration: line-through;
					padding-left: 10px;
				}
			}
		}
		> thead > tr > th {
			vertical-align: middle;
			border: none;
			padding: 30px;
		}
	}
}
.table > {
	tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
		vertical-align: middle;
		border: none;
		padding: 30px;
	}
}
.body-content {
	.my-wishlist-page .my-wishlist table tbody .close-btn a {
		color: #ff7878;
		font-size: 15px;
		&:hover {
			color: #ff7878;
			font-size: 15px;
		}
	}
	.sign-in-page {
		.sign-in h4, .create-new-account h4 {
			font-size: 24px;
			font-family: 'FjallaOneRegular';
			padding-bottom: 14px;
			border-bottom: 1px solid #ddd;
			text-transform: uppercase;
		}
		.sign-in p, .create-new-account p {
			font-size: 15px;
			color: #666;
		}
		.sign-in .social-sign-in {
			a {
				border-radius: 3px;
				padding: 14px 30px;
				font-size: 15px;
				display: inline-block;
				color: #fff;
				text-align: center;
				&:hover, &:focus {
					border-radius: 3px;
					padding: 14px 30px;
					font-size: 15px;
					display: inline-block;
					color: #fff;
					text-align: center;
				}
				i {
					padding-right: 6px;
				}
			}
			.facebook-sign-in {
				background-color: #3d5c98;
				margin-right: 10px;
				&:hover, &:focus {
					background-color: #153470;
				}
			}
			.twitter-sign-in {
				background-color: #22aadf;
				&:hover, &:focus {
					background-color: #0084B9;
				}
			}
		}
		.create-new-account {
			> span {
				font-size: 20px;
				font-family: 'FjallaOneRegular';
				padding-bottom: 14px;
				text-transform: uppercase;
				display: inline-block;
			}
			.checkbox label {
				margin-bottom: 10px;
				font-size: 16px;
			}
		}
		form .form-group span {
			color: red;
		}
		.register-form {
			label {
				font-size: 14px;
				font-weight: 400;
			}
			.form-group {
				margin-bottom: 25px;
			}
		}
	}
}