@media screen and (max-width: 640px) {
.top-bar {
	padding: 0;
	.header-top-inner {
		padding: 0;
		.cnt-account {
			float: left;
			li {
				margin: 0;
				padding: 0;
				float: left;
				a {
					i.icon {
						font-size: 20px;
						padding: 5px 8px;
					}
					span {}
				}
			}
		}
		.cnt-block {
			> ul.list-inline{
				> li {
					> a {
						padding: 5px 10px 5px 5px;
						span {
							&.key { display: none; }
						}
					}
					.dropdown-menu {
						left: auto;
					    right: 6px;
					    top: 102%;
					    min-width: 65px;
					    > li > a {
					    	padding: 3px 0 0 15px;
					    	min-width: 65px;
					    }
					}
				}
			}
		}
	}
}

.main-header {
	padding: 10px 0;
	.logo-holder {
		margin-top: 0;
		margin-bottom: 0;
	}
	.top-search-holder {
		.search-area {
			margin-top: 15px;
		}
	}
	.top-cart-row {
		position: fixed;
		bottom: 10px;
		z-index: 10;
		.dropdown-cart {
			border: 1px solid #e1e1e1;
			float: left;
			box-shadow: 0 0 2px 5px white;
			.dropdown-toggle.lnk-cart {
				float: left;
			}
		}
	}
	.top-cart-row {
		.dropdown-cart .dropdown-menu {
			display: none !important;
		}
		.total-price-basket .lbl {
			display: none !important;
		}
	}
}


}